import axios from 'axios';
import * as utils from '../../../commons/utils';

// axios 인터셉터 설정
const instance = axios.create({});

instance.interceptors.request.use(
    (config) => {
        config.params = {...config.params, type : "EW"};
        return config;
    }
);

instance.interceptors.response.use(
    (response)  => {
        return response;
    },
    (error) => {
        if(error.response.status === 403) {
            
            const domain = sessionStorage.getItem('domain');

            utils.openPopup({
                url: `/${domain}?type=simple`,
                target: '_blank',
                width: '700',
                height: '700',
                align: 'center'
            });

            return Promise.reject(error);
        }
    }
);

/**
 * 로그인 
 * 0000 : 로그인 성공
 * 1111 : 로그인정보가 일치하지 않습니다. / 로그인에 실패하였습니다.
 */
export function login(requestParam) {
    return new Promise(function(resolve, reject){
        axios({
            method:"post",
            url:"/apiLoginChk.do",
            data: requestParam

        }).then((res)=>{
            if(res.data.resultCode === "0000") {
                resolve(res);
            }else {
            //로그인 실패
            reject(res.data.resultMessage);             
            }        
        }).catch((err)=>{
            console.log(err.message);
            reject("로그인에 실패하였습니다. "+ (err.message&&(" ["+err.message+"]")));
        });

    });
}


/**
 * 딜러별 SIGNOK 정보 조회 (API KEY, API ID, TEMPLATE ID)
 */
export function getSignokDealerInfo(dealerCd) {
    return new Promise(function(resolve, reject){
        instance({
            method:"post",
            url:"/apiGetDealerMeta.do",
            data: {
                dealer_cd: dealerCd,
                item_gubun: 'SIGNOK'
            }

        }).then((res)=>{
            if(res.data.resultCode === "0000") {
                resolve(res.data.signokInfo);
            }else {
                reject("전자계약서 조회에 실패하였습니다. 관리자에게 문의하세요");
            }        
        }).catch((err)=>{
            reject("전자계약서 조회에 실패하였습니다. 관리자에게 문의하세요");
        });

    });
};

/**
 * EW기본정보 조회 
 */
export function requestClnBasicInfo(searchParam) {
    return new Promise(function(resolve, reject){
        //계약서번호, 차량번호, 차대번호

        instance({
            method:"post",
            url:"/ewApiSearchBasicInfo.do",
            data: searchParam, 
            headers : {"Content-Type" : "application/json"}
        
        }).then((res)=>{
            resolve(res.data);

        }).catch((err)=>{
            reject("기본정보 조회에 실패하였습니다.");
        });        
    });
}

/**
 * 전시장 목록 조회  
 */
export function getShowRoomList() {
    return new Promise(function(resolve, reject){
        instance({
            method:"post",
            url:"/apiGetShowroomList.do",
            headers : {"Content-Type" : "application/json"}
        
        }).then((res)=>{
            resolve(res.data)
        }).catch((err)=>{
            reject("전시장 조회에 실패하였습니다.");
        });        
    });
}

/**
 * 부서 목록 조회 
 */
export function getDeptList(showroomNo) {
    return new Promise(function(resolve, reject){
        instance({
            method:"post",
            url:"/apiGetDeptList.do",
            data:{showroom_no : showroomNo},
            headers : {"Content-Type" : "application/json"}
        
        }).then((res)=>{
            resolve(res.data)
        }).catch((err)=>{
            reject("부서 조회에 실패하였습니다.");
        });        
    });
}

/**
 * 사용자 조회
 */
export function getEmpList(requestParam) {
    return new Promise(function(resolve, reject){
        instance({
            method:"post",
            url:"/apiGetEmpList.do",
            data:requestParam,
            headers : {"Content-Type" : "application/json"}
        
        }).then((res)=>{
            resolve(res.data)
        }).catch((err)=>{
            reject("사용자 조회에 실패하였습니다.");
        });        
    });
}

/**
 * 코드값 + 전시장 조회
 */
export function ewApiGetCode() {
    return new Promise(function(resolve,reject){
        instance({
            method:"post",
            url:"/ewApiGetCode.do"
        }).then((res)=>{
            resolve(res.data);
        }).catch((err)=>{
            reject("EW 정보 조회에 실패하였습니다.");
        }); 
    })
   
 }
/**
 * 차종 조회
 */
export function ewApiGetCarType(model_no) {
    return new Promise(function(resolve,reject){

        instance({
           method: "post",
           url:"/getSelectBoxCarTypeInfo.do",
           data: {"model_no" : model_no}
       }).then((res)=>{
           resolve(res.data.carType);
           
       }).catch((err)=>{
        reject("차종 조회에 실패하였습니다.");
       });
    })


 }
/**
 * 차량년식 조회
 */
export function ewApiGetCarYear(model_no,class_no){
    return new Promise(function(resolve,reject){


        instance({
            method: "post",
            url: "/getSelectBoxCarYearInfo.do",
            data: {"model_no" : model_no, "class_no" : class_no}
        }).then((res)=>{
            resolve(res.data.carYear);
        }).catch((err)=>{
            reject("년식 조회에 실패하였습니다.");
        });
    });

 }

 /**
  * EW 상품가격, 인센티브 조회
  */
 export function ewApiGetEwPrice(requestParam){
    return new Promise(function(resolve, reject){

        instance({
            method: "post",
            url : "/ewGetProductPrice.do",
            data: requestParam
        }).then((res)=>{
            (Object.keys(res.data).length>0) ? resolve(res.data):reject("상품가격이 존재하지 않습니다.");
        }).catch((err)=>{
            reject("EW상품가격 조회에 실패하였습니다.");
        });
    });
 }

/**
 * EW Mng table 등록
 */
export function insertEwMng(requestParam) {
    return new Promise(function(resolve, reject){  
        instance({
            method:"post",
            url:"/ewInsertEwMngApi.do",
            data: requestParam,
            headers : {"Content-Type" : "application/json"}
    
        }).then((res)=>{
            if(res.data.resultCode === "0000") { 
                resolve(res.data.resultValue);
            }else {
                reject("EW 계약서 등록에 실패하였습니다.");
            }
        }).catch((err)=>{
            reject("EW 계약서 등록에 실패하였습니다.");
        });
    }); 
}

/**
 * EW 전자계약서 진행상태 업데이트
 */
export function ewSignCompleteApi(requestParam) {
    return new Promise(function(resolve, reject){  
        instance({
            method:"post",
            url:"/ewSignCompleteApi.do",
            data: requestParam,
            headers : {"Content-Type" : "application/json"}
    
        }).then((res)=>{
            if(requestParam.status === "N" || res.resultCode === "1111" ) {
                reject("EW 등록에 실패하였습니다, 전자계약서를 확인하세요.")
            } else {
                resolve("EW 등록 성공");
            }
        }).catch((err)=>{
            reject("EW 등록에 실패하였습니다, 전자계약서를 확인하세요.");
        });
    }); 
}

/**
 * 가상계좌 할당
 */
export function ewAssignVirtualAccount(contNo) {
    return new Promise(function(resolve, reject){  
        instance({
            method:"post",
            url:"/ewAssignVirtualAccount.do",
            data: {cont_no: contNo},
            headers : {"Content-Type" : "application/json"}
        }).then((res)=>{
            if(res.data === ""){
                reject("가상계좌 할당에 실패하였습니다.");
            }else if(res.data === "0"){
                reject("해당 전시장에 가상계좌가 존재하지 않습니다.")
            }
            else{
                resolve(res.data.virtaulAccountNo);
            }
        }).catch((err)=>{
            reject("가상계좌 할당에 실패하였습니다.");
        });
    }); 
}
/**
 * SMS 전송
 */
export function sendSMS(requestParam) {
    /**
     * requestParam example
     * {
     *   msg_body: "내용",
     *   send_phone: "010-0000-0000",
     *   send_name: "보내는사람",
     *   dest_phone: "010-1234-5678",
     *   dest_name: "받는사람"
     * }
     */
    return new Promise(function(resolve, reject){
        instance({
            method:"post",
            url:"/apiSendSms.do", 
            data: requestParam,
            headers : {"Content-Type" : "application/json"}
        }).then((res) => {
            if(res.data.resultCode === "0000") {
                resolve("SMS 전송에 성공하였습니다.");
            }else{
                reject("SMS 전송에 실패하였습니다.");
            }
        }).catch((err)=>{
            reject("SMS 전송에 실패하였습니다.");
        });
    });
}

/**
 * 차량번호 중복확인
 */
export function ewDuplCarNo(carNo) {

    return new Promise(function(resolve, reject){

        if(!carNo??"") {
            reject();
            return;
        }

        instance({
            method:"post",
            url:"/ewDuplCarNo.do", 
            params : {carNo : carNo},
            headers : {"Content-Type" : "application/json"}
        }).then((res) => {
            if(res.data.length > 0){
                resolve(res.data);
            }else{
                reject(res.data);
            }
        }).catch((err)=>{
            reject(err);
        });
    });
}


/**
 * 신규 차량 계약일 중복확인
 */
export function getSameEwDtList(requestParam) {

    return new Promise(function(resolve, reject){

        const errorMessage = "신규 차량 중복 확인에 실패하였습니다.";

        const body = {
            ew_start_dt : requestParam.ew_start_dt.format("YYYY-MM-DD")??"",
            ew_end_dt : requestParam.ew_end_dt.format("YYYY-MM-DD")??"",
            car_no : requestParam.car_no
        }

        if(!body.car_no??"") {
            reject(errorMessage);
            return;
        }
        instance({
            method:"post",
            url:"/apiCheckEwPeriod.do", 
            data : body,
            headers : {"Content-Type" : "application/json"}
        }).then((res) => {
            if(res.data.resultCode === "1111"){
                reject(errorMessage);
            }else{
                resolve(res.data);
            }
        }).catch((err)=>{
            reject(errorMessage);
        });
    });
}

/**
 * 고객 정보 조회
 */
export function getClientList(requestParam) {

    const errorMessage = "고객 정보 조회에 실패하였습니다.";
    return new Promise(function(resolve, reject){
        instance({
            method:"post",
            url:"/apiGetClientList.do",
            data: requestParam,
            headers : {"Content-Type" : "application/json"}
        }).then((res) => {
            if(res.data.resultCode === "1111"){
                reject(errorMessage);
            }else{
                resolve(res.data);
            }
        }).catch((err)=>{
            reject(errorMessage);
        });
    });
}

/**
 * 코드 조회
 */
export function getCodeList(requestParam) {
    const errorMessage = "데이터 조회에 실패하였습니다.";
    return new Promise(function(resolve, reject){
        instance({
            method:"post",
            url:"/apiGetCodeList.do",
            data: requestParam,
            headers : {"Content-Type" : "application/json"}
        }).then((res) => {
            if(res.data.resultCode === "1111"){
                reject(errorMessage);
            }else{
                resolve(res.data.codeList);
            }
        }).catch((err)=>{
            reject(errorMessage);
        });
    });
}

/**
 * EW 계약서 조회
 */
export function getEwList(requestParam) {
    const errorMessage = "목록 조회에 실패하였습니다.";
    return new Promise(function(resolve, reject){
        instance({
            method:"post",
            url:"/ewApiGetList.do",
            data: requestParam,
            headers : {"Content-Type" : "application/json"}
        }).then((res) => {
            if(res.data.resultCode === "1111"){
                reject(errorMessage);
            }else{
                resolve(res.data);
            }
        }).catch((err)=>{
            reject(errorMessage);
        });
    });
}

/**
 * EW 계약서 조회
 */
export function deleteEwMng(ewContNo) {
    const errorMessage = "계약서 삭제에 실패하였습니다.";
    return new Promise(function(resolve, reject){

        if(!ewContNo) reject(errorMessage);

        instance({
            method:"post",
            url:"/ewDeleteEwMng.do",
            data: {ew_cont_no: ewContNo},
            headers : {"Content-Type" : "application/json"}
        }).then((res) => {
            if(res.data.resultCode === "1111"){
                reject(errorMessage);
            }else{
                resolve(res.data);
            }
        }).catch((err)=>{
            reject(errorMessage);
        });
    });
}

/**
 * 개인정보활용동의 팝업 호출
 */
export function popClnSignView(){

    const url = process.env.REACT_APP_CRM_API_URL;

    const JSESSIONID = utils.getCookie("JSESSIONID"); 
    const data = {
        JSESSIONID : JSESSIONID
    };

    window.addEventListener("message", handleMessage);
    
    var popup = window.open(url + "/popupClientSignViewTemp.do");

    function handleMessage(event){
        if(event.data === "load") {
            popup.postMessage(data, url);
            window.removeEventListener("message", handleMessage);
        }
    }
    
    
}