import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import * as confStore from '../../stores/confStore';
import '../../styles/layouts/Footer.css';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Paper from '@mui/material/Paper';


function BottomNav() {
    
    const navigate = useNavigate();

    const [value, setValue] = useState(-1);

    const activeMenu = useRecoilValue(confStore.activeMenu);

    useEffect(() => {

        let activeIndex = activeMenu.findIndex(obj => obj.route === window.location.pathname);

        if(window.location.pathname === '/ewForm') return;

        setValue(activeIndex !== -1 && ++activeIndex);
        
    }, [window.location.pathname])

    const handleMenuClick = (page, index) => {
		if(!page) {
			alert("메뉴 준비중"); 
			return;
		}

        navigate(page);
        setValue(index);
    }

    return (
        (window.location.pathname !== '/home' && Object.keys(activeMenu[0]).length > 0) && (
        <Box sx={{ pb: 8 }} >
            <Paper  
                sx={{
                        position: 'fixed', 
                        bottom: 0, 
                        margin: '0 auto',
                        boxShadow: 'none', 
                        width: '100%', 
                        maxWidth: '1200px',
                        zIndex: 2
                    }}
            >
            <BottomNavigation
                showLabels
                value={value}
                sx={{boxShadow: '0px -4px 3px -2px rgba(0,0,0,0.2)',  backgroundColor: '#4c88ea'}}
            >
                <BottomNavigationAction className='bottom-nav-button' label="Home" icon={<HomeOutlinedIcon />} onClick={() => handleMenuClick('/home', 0)} />
                {activeMenu.map((obj, index) => (
                    <BottomNavigationAction 
                        className='bottom-nav-button'
                        sx={{
                            '&.Mui-selected': {
                                                color: '#4c88ea !important',
                                                backgroundColor: 'white !important',
                            }
                        }}
                        key={index + 1} 
                        label={obj.title} 
                        icon={obj.icon} 
                        onClick={() => handleMenuClick(obj.route, index + 1)}
                    />
                ))}
            </BottomNavigation>
            </Paper>
        </Box>
        )
    );
}

export default BottomNav;