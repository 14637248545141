import React, { useState, useEffect, useRef } from 'react';
/**외부 */
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { DataGrid, gridClasses  } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'; 
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
/**내부 */
import * as crm4u from '../services/apis/crm4u/crmApi';
import CPSelectTag from './comm/CPSelectTag';
import { alertState } from '../stores/ComponentState';
import CPCircularProgress from '../components/comm/CPCircularProgress';
import * as utils from '../commons/utils';
import CPPaging from './comm/CPPaging';
import{  HelperTextBox } from '../styles/ewMng/EwFormStyle';
import { userState } from '../stores/UserState';

function CPClnRegisterModal(props) {
    const userInfo = useRecoilValue(userState); //사용자정보
    const setAlertInfo = useSetRecoilState(alertState);
    const [searchParam, setSearchParam] = useState({client_nm:'', 
                                                    iso_vin:'', 
                                                    phone_no:'', 
                                                    showroom_no: userInfo.showroom_no, 
                                                    dept_no: userInfo.dept_no, 
                                                    emp_id: props.selectAuth ? "" : userInfo.emp_id});
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState([]);
    const [validSearchInfo, setValidSearchInfo] = useState(false);
    const [view, setView] = useState(false);

    const init = useRef(true);

    const columns = [{ field: 'client_nm', headerName: '고객명', sortable:false, width:150}
                    ,{ field: 'phone_no', headerName: '전화번호', sortable:false, width:150}
                    ,{ field: 'model_nm', headerName: '모델명', sortable:false, width:100}
                    ,{ field: 'iso_vin', headerName: '차대번호', sortable:false, width:100}
                    ,{ field: 'sales_consultant', headerName: '담당자', sortable:false, width:100}
                    ];
    const { register,
            setValue,
            getValues,
            watch,
            control,
            } = useForm({ defaultValues: {
                                                showroom_no: '',
                                                dept_no: '',
                                                emp_id: props.selectAuth ? "" : userInfo.emp_id
                                                
    }});

    // Select 태그 전시장 리스트
	const [showRoomList, setShowRoomList] = useState([]);
    // Select 태그 부서 리스트
	const [deptList, setDeptList] = useState([]);

    useEffect (() => {
        setShowRoomList(props.showRoomList);
        setDeptList(props.deptList, setView(true));

        if(!props.selectAuth) {
            setValue("showroom_no", userInfo.showroom_no);
            setValue("dept_no", userInfo.dept_no);
        }
    },[]);

    // 결과 카운트
	const [totalCount, setTotalCount] = useState(0);
    // 한 페이지에 보여줄 행 수
	const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
    // 현재 페이지
    const [page, setPage] = useState(1);

    //전시장 변경시 부서 세팅
    const changeShowRoom = (e) => {
        const showroomNo = e.target.value;
        
        setValue("dept_no", "");
        
        crm4u.getDeptList(showroomNo).then((res) => {
            setDeptList(res.deptList);
        });
    }

    const getClientList = (pageNum) => {
        setLoading(true);

        setPage(pageNum);
        crm4u.getClientList({...searchParam, pageNum: pageNum})
            .then((res)=>{
                setResult(res.clientList);
                setTotalCount(res.totalCount);
            })
            .catch((err)=>{
                setAlertInfo({message :err, isOpen:true});
            })
            .finally(()=>{
                setLoading(false);
            });
    }
    
    //조회 버튼 클릭 -> 검색조건 세팅
    const clickSearchBtn = () => {
        init.current = false;

        if(watch("client_nm").trim() === "" && watch("iso_vin").trim() === "" && watch("phone_no").trim() === ""){
            setValidSearchInfo(true);
        } else {
            setSearchParam(getValues());
        }
    };

    //검색조건 세팅 후 조회
    useEffect(() => {
        if(init.current === false) getClientList(1);
    }, [searchParam]);

    const tableRowEvents = (e) =>{
        crm4u.ewDuplCarNo(e.row.iso_vin).then((res) => {
            if(!!res){
                if(window.confirm(`진행중인 계약이 있는 차량입니다. \n(계약서 번호 : ${res[0]['ew_cont_no']} / 보증 종료일 : ${utils.toStringByFormat(res[0]['ew_end_dt'])})\n계속 진행하시겠습니까?`)){
                    setRowData(e);
                };
            } else {
                setRowData(e);
            }
        }).catch((err) => {
            setRowData(e);    
        })
    };

    const setRowData = (e) => {
        props.inputClnInfo(e.row);
        props.onClnSearchModal(false);  
    }

    return (
        view &&
        <>
            <Dialog open={true}
                    onClose={() => props.onClnSearchModal(false)}
                    maxWidth="md">
                <DialogTitle sx={{ m: 0, p: 2, fontSize:'1.1rem', fontWeight:550}}>고객/차량 조회 
                    <span style={{'fontSize': '0.8em', 'fontWeight': '400', 'color' : '#6f6868'}}> (영업사원(SC)의 경우 본인의 고객만 조회 가능합니다.)</span>
                </DialogTitle>

                <IconButton aria-label="close"
                            onClick={() => props.onClnSearchModal(false)}
                            sx={{ position: 'absolute',
                                  right: 8,
                                  top: 8,
                                }}> 
                            <CloseIcon />
                </IconButton>
    
                <DialogContent dividers>
                    <div className='subTitle'>
                        <div className='subTitle-btn'>
                            <Button size='small' variant="contained" onClick={clickSearchBtn} disabled={loading}>조회{loading && <CPCircularProgress/>}</Button>
                        </div>
                    </div>                 
                    <Stack direction="row" spacing={2} sx={{mb: 2, width:'100%'}} justifyContent="space-around">
                        <CPSelectTag 
                            control={control}
                            data={showRoomList}
                            name="showroom_no" 
                            value="showroom_no" 
                            text="showroom_nm" 
                            label="담당SC 전시장"
                            defaultMessage="전체"
                            readOnly={!props.selectAuth}
                            onChange={(e) => {changeShowRoom(e)}}
                        />
                        <CPSelectTag 
                            control={control}
                            data={deptList}
                            name="dept_no" 
                            value="dept_no" 
                            text="dept_name"
                            label="담당SC 부서" 
                            readOnly={!props.selectAuth}
                            defaultMessage="전체"
                        />
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{mb: 2, width:'100%'}} justifyContent="space-around">
                        <TextField 
                            label="고객명" 
                            size='small' 
                            fullWidth 
                            {...register("client_nm")}
                            inputProps={{ maxLength: 20}}
                            onKeyDown={e => e.key === "Enter" ? clickSearchBtn() : setValidSearchInfo(false)}/>
                        <TextField 
                            label="차대번호" 
                            size='small' 
                            fullWidth 
                            inputProps={{ maxLength: 20}}
                            {...register("iso_vin")}
                            onKeyDown={e => e.key === "Enter" ? clickSearchBtn() : setValidSearchInfo(false)}/>
                        <TextField 
                            label="휴대폰번호" 
                            size='small' 
                            fullWidth 
                            inputProps={{ maxLength: 13}} 
                            {...register("phone_no")}
                            onChange={(e) => {setValue("phone_no", utils.onlyPhoneNumber(e.target.value))}} 
                            onKeyDown={e => e.key === "Enter" ? clickSearchBtn() : setValidSearchInfo(false)}/>
                    </Stack>
                    {validSearchInfo &&<HelperTextBox sx={{color: "#d32f2f", margin: "-5px 0px 10px 0 !important", width: "100% !important"}}>{"고객명, 차대번호, 전화번호 중 하나를 입력해 주세요."}</HelperTextBox>}    
                    <DataGrid
                        disableColumnMenu
                        autoHeight
                        rows={ result }
                        getRowId={(row) => row.num}
                        getRowHeight={() => 'auto'}
                        columns={ columns }
                        onRowClick={(e) => tableRowEvents(e)}
                        sx={{ '--DataGrid-overlayHeight': '100px',
                        [`& .${gridClasses.cell}`]: {
                            py: 1,
                          },
                        '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                            },
                        '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer'
                        },
                        '& .MuiDataGrid-row': {
                            minHeight: '52px !important'
                        }
                        }}
                        slots={{
                            noRowsOverlay: () => (
                                <Box sx={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>조회 결과가 없습니다.</Box>
                            )
                        }}
                        hideFooter
                        
                    />
                    <CPPaging page={page} totalItemsCount={totalCount} func={getClientList} itemsCountPerPage={itemsCountPerPage}/>
                </DialogContent>
            </Dialog>
        </>
      );
};

export default CPClnRegisterModal;