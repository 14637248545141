import React, { useEffect, useState, useRef } from 'react';
/**외부 */
import { useSetRecoilState } from 'recoil';
import { DataGrid, gridClasses  } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'; 
import Stack from '@mui/material/Stack';
/**내부 */
import CPPaging from './comm/CPPaging';
import * as crm4u from '../services/apis/crm4u/crmApi';
import { alertState } from '../stores/ComponentState';
import CPCircularProgress from '../components/comm/CPCircularProgress';
import * as utils from '../commons/utils';
import{ HelperTextBox } from '../styles/ewMng/EwFormStyle';

function CPAppSearchModal(props) {

    const setAlertInfo = useSetRecoilState(alertState);

    const init = useRef(true);

    const [searchParam, setSearchParam] = useState({cont_no:'', car_no:'', iso_vin:''});
    const [searchHistory, setSearchHistory] = useState({cont_no:'', car_no:'', iso_vin:''});
    const [loading, setLoading] = useState(false);
    const [validSearchInfo, setValidSearchInfo] = useState(false);
    
    const [result, setResult] = useState([]);
	const [totalCount, setTotalCount] = useState(0); //결과 행 수
	const [itemsCountPerPage, setItemsCountPerPage] = useState(10); //페이지당 행 수 
    const [page, setPage] = useState(1); //현재 페이지

    const columns = [{ field: 'cont_no'   ,headerName: '계약서번호', sortable:false, width:100}
                    ,{ field: 'car_no'    ,headerName: '차량번호', sortable:false, width:100}
                    ,{ field: 'iso_vin'   ,headerName: '차대번호', sortable:false, width:180}
                    ,{ field: 'client_nm' ,headerName: '고객명', sortable:false, width:100}
                    ,{ field: 'comp_nm'   ,headerName: '법인명', sortable:false, width:100}
                    ,{ field: 'phone_no'  ,headerName: '전화번호', sortable:false, width:150}];

    const handleSerchParam = (e) => {
        const {name,value} = e.target;
        setSearchParam((prevState)=> {
            return { ...prevState, [name]: value}
        });
    };
    
    const clickSerchBtn = () => {
        setAlertInfo({isOpen: false});

        if(searchParam.cont_no === "" && searchParam.car_no === "" && searchParam.iso_vin === ""){
            setValidSearchInfo(true);
        } else {
            init.current = false;
            setSearchHistory(searchParam);
        }
    
    };
    
    useEffect(() => {
        if(init.current === false) getInfoList(1);
    }, [searchHistory]); 

    const getInfoList = (pageNum) => {
        setLoading(true);

        setPage(pageNum);
        crm4u.requestClnBasicInfo({...searchHistory, pageNum: pageNum})
        .then((res) => {
            setResult(res.infoList);
            setTotalCount(res.totalCount);
            setItemsCountPerPage(res.size);
        })
        .catch((err)=>{
            setAlertInfo({message :err, isOpen:true});
        })
        .finally(()=>{
            setLoading(false);
        });
    }



    const setRowData = (e) => {
        props.inputBasicInfo(e.row);
        props.onBasicInfoModal(false);  
    }

    const tableRowEvents = (e) =>{
        crm4u.ewDuplCarNo(e.row.car_no).then((res) => {
            if(!!res){
                if(window.confirm(`진행중인 계약이 있는 차량입니다. \n(계약서 번호 : ${res[0]['ew_cont_no']} / 보증 종료일 : ${utils.toStringByFormat(res[0]['ew_end_dt'])})\n계속 진행하시겠습니까?`)){
                    setRowData(e);
                };
            } else {
                setRowData(e);
            }
        }).catch((err) => {
            setRowData(e);    
        })
    };

    return (
    <>
        <Dialog open={true}
                onClose={() => props.onBasicInfoModal(false)}
                maxWidth="md">
            <DialogTitle sx={{ m: 0, p: 2, fontSize:'1.1rem', fontWeight:550}}>품의서 조회 <span style={{'fontSize': '0.8em', 'fontWeight': '400', 'color' : '#6f6868'}}>(출고예정, 출고 건만 조회가 가능합니다)</span></DialogTitle>
            <IconButton aria-label="close"
                        onClick={() => props.onBasicInfoModal(false)}
                        sx={{ position: 'absolute',
                              right: 8,
                              top: 8,
                            }}> 
                        <CloseIcon />
            </IconButton>

            <DialogContent dividers>
                <div className='subTitle'>
                    <div className='subTitle-btn'>
                        <Button size='small' variant="contained" onClick={clickSerchBtn} disabled={loading}>조회{loading && <CPCircularProgress/>}</Button>
                    </div>
                </div>                 
                
                <Stack direction="row" spacing={2} sx={{mb: 2, width:'100%'}} justifyContent="space-around">
                    <TextField 
                        label="계약서번호" 
                        name="cont_no" 
                        size='small' 
                        fullWidth 
                        onChange={handleSerchParam}
                        onKeyDown={e => e.key === "Enter" ? clickSerchBtn() : setValidSearchInfo(false)}/>
                    <TextField 
                        label="차량번호" 
                        name="car_no" 
                        size='small' 
                        fullWidth 
                        onChange={handleSerchParam}
                        onKeyDown={e => e.key === "Enter" ? clickSerchBtn() : setValidSearchInfo(false)}/>
                    <TextField 
                        label="차대번호" 
                        name="iso_vin" 
                        size='small' 
                        fullWidth 
                        onChange={handleSerchParam}
                        onKeyDown={e => e.key === "Enter" ? clickSerchBtn() : setValidSearchInfo(false)}/>
                </Stack>
                {validSearchInfo &&<HelperTextBox sx={{color: "#d32f2f", margin: "-5px 0px 10px 0 !important", width: "100% !important"}}>{"계약서 번호, 차량번호, 차대번호 중 하나를 입력해 주세요."}</HelperTextBox>}    
                <DataGrid
                    disableColumnMenu
                    autoHeight
                    rows={ result }
                    getRowId={(row) => row.num}
                    getRowHeight={()=>'60px'}
                    columns={ columns }
                    onRowClick={(e) => tableRowEvents(e)}
                    sx={{ '--DataGrid-overlayHeight': '100px',
                    [`& .${gridClasses.cell}`]: {
                        py: 1,
                      },
                        //   '& .MuiDataGrid-columnHeader':{display:'table'},
                        //   '& .css-jak0ql-MuiButtonBase-root-MuiIconButton-root':{padding:0}
                    '.MuiDataGrid-cell:focus': {
                        outline: 'none'
                        },
                        // pointer cursor on ALL rows
                    '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer'
                    }  
                    }}
                    slots={{
                        noRowsOverlay: () => (
                            <Box sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>조회 결과가 없습니다.</Box>
                        )
                    }}
                    hideFooter
                    
                />        
                <CPPaging page={page} totalItemsCount={totalCount} func={getInfoList} itemsCountPerPage={itemsCountPerPage}/>
            </DialogContent>
        </Dialog>
    </>
  );
};

export default CPAppSearchModal;