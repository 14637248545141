import React, { useEffect, useState, useCallback } from 'react';
/**외부 */
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';

/**mui */
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'; 
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import{ ProductInfoStack, HelperTextBox, WarningTextBox, TitleChip, ContentPaper, ContentPaper1, InfoCheckIcon} from '../../styles/ewMng/EwFormStyle';
import { RadioGroup, Radio } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import Skeleton from '@mui/material/Skeleton';
//mui icon
import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';
import ClearIcon from '@mui/icons-material/Clear';


/**내부 */
import '../../styles/ewMng/EwForm.css';
import '../../styles/base.css';
import * as utils from '../../commons/utils';
import * as signOk from '../../services/apis/signOk/signOkApi';
import * as crm4u from '../../services/apis/crm4u/crmApi';
import { userState } from '../../stores/UserState';
import { alertState, loadingState } from '../../stores/ComponentState';
import CPDatePicker from '../../components/comm/CPDatePicker';
import CPAppSearchModal from '../../components/CPAppSearchModal';
import CPSelectTag from '../../components/comm/CPSelectTag';
import CPEmpSearchModal from '../../components/CPEmpSearchModal';
import CPClnSearchModal from '../../components/CPClnSearchModal';
import CPManualBtn from '../../components/comm/CPManualBtn';


function EwForm() {

    const userInfo = useRecoilValue(userState); //사용자정보
    const setAlertInfo = useSetRecoilState(alertState); //alert셋팅
    const setLoadingInfo = useSetRecoilState(loadingState); //loading셋팅
    
    const location = useLocation();
    const xsVal = 6;
    const smVal = 4;
    const mdVal = 4;
    
    const { register,
            handleSubmit,
            setValue,
            getValues,
            watch,
            control,
            formState: { errors, isSubmitted },
          } = useForm({ 
                           defaultValues: { // register 기본값
                                            dealer_cd  : userInfo.dealer_cd,
                                            writer     : userInfo.emp_id,
                                            sell_showroom_no: userInfo.showroom_no,
                                            showroom_nm: userInfo.showroom_nm,
                                            discount_price: 0,
                                            ew_product_sales_dt : dayjs(new Date()),
                                            car_enroll_dt: null,
                                            ew_start_dt: null,
                                            ew_end_dt: null,
                                            ew_corp_nm: "",
                                            car_model_no: "",
                                            car_class_no: "",
                                            car_year_no: "",
                                            ew_model_cd: "",
                                            tax_bill_req_cd: "TBR00010"
                     }});
    /**템플릿 정보 */                 
    const {templateId, signerId, templateTitle, isNonFace} = location.state;
    
    /**
     * 고객 조회 권한
     * [PE, 정비서비스, 정비관리, EW일반관리자, EW최고관리자] 권한 있는 경우 true
     */
    const [selectAuth, setSelectAuth] = useState(() => utils.isAuthorized(userInfo.authList, ['AUT00130', 'AUT00140', 'AUT00190', 'AUT00410', 'AUT00420']));

    /**상품선택 */            
    const [productGuide, setProductGuide] = useState(''); //상품선택 가이드(신차 출고 등록일 기준)
    /**기본정보 */
    const [showRoomList, setShowRoomList] = useState([]); //판매지점
    const [empModalInfo, setEmpModalInfo] = useState('');//Modal에 넘길 데이터
    const [clnModalInfo, setClnModalInfo] = useState('');
    const [clnCompList, setClnCompList] = useState([]);//법인리스트
    /**상품정보 */
    const [ewDivCd, setEwDivCd] = useState([]); //ew구분코드
    const [ewDivCdNm, setEwDivCdNm] = useState([]); //ew상품명
    const [ewPeriodCd, setEwPeriodCd] = useState([]); //담보기간코드
    const [selEwPeriod, setSelEwPeriod] = useState(''); //담보기간 선택값
    const [ewDiv, setEwDiv] = useState([{}]); //EW상품
    const ewStartKm = '100000'; 
    const dtFlag = { d: '일', m: '개월', y: '년'};
    const [availEnrollDt, setAvailEnrollDt] = useState(''); //가입가능 차량등록일
    const [availEnrollDtFlag, setAvailEnrollDtFlag] = useState(''); //가입가능 차량등록일 구분 (일,월,년)
    const [availDistance, setAvailDistance] = useState(''); //가입가능 주행거리
    const [wrntdPeriod, setWrntdPeriod] = useState('');     //보장기간 및 주행거리
    const [elapsedDt, setElapsedDt] = useState(0);         //차량등록일부터 상품판매 경과일
    const [checkAvailEnrollDt, setCheckAvailEnrollDt] = useState('');  //EW선택상품의 차량등록일 조건에 부합하는지
    const [checkAvailDistance, setCheckAvailDistance] = useState('');  //EW선택상품의 가입가능 주행거리 조건에 부합하는지

    // 모델, 차종, 연식
    const [carModel, setCarModel] = useState([{}]); 
    const [carType, setCarType] = useState([{}]); 
    const [carYear, setCarYear] = useState([{}]); 
    const [ewModelCd, setEwModelCd] = useState([]); 
    // 모달창
    const [basicInfoModalYn, setBasicInfoModalYn] = useState(false);
    const [empSearchModalYn, setEmpSearchModalYn] = useState(false);
    const [clnSearchModalYn, setClnSearchModalYn] = useState(false);
    // 고객/차량데이터 readonly
    const [clnReadOnly, setClnReadOnly] = useState(true);
    const [carReadOnly, setCarReadOnly] = useState(true);
    const [isNewCar, setIsNewCar] = useState(false);
    const [isNewCln, setIsNewCln] = useState(false);
    const [isContNo, setIsContNo] = useState(false);
    const [carModelRO, setCarModelRO] = useState(false);
    const [carTypeRO, setCarTypeRO] = useState(false);
    const [carYearRO, setCarYearRO] = useState(false);
    const [carNoRO, setCarNoRO] = useState(false);
    const [isoVinRO, setIsoVinRO] = useState(false);

    let navigate = useNavigate();//페이지이동
    /**
     * lifeCycle, 처음에만 호출 
     **/
    useEffect (() => {
        // ew관련 코드 값, 모델, 차종, 연식 데이터 조회
        crm4u.ewApiGetCode().then((res) =>{
            setEwDivCd(res.cdMap.EW_DIV);
            setEwPeriodCd(res.cdMap.EW_PERIOD);
            setEwModelCd(res.cdMap.EW_CAR_MODEL);
            setCarModel(res.carModel);
            setShowRoomList(res.showRoomList);

            //초기 전시장 전화번호 세팅
            const userShowRoom = res.showRoomList?.find((obj) => obj?.showroom_no === userInfo.showroom_no);
            setValue('sell_showroom_phone_no', userShowRoom?.phone_no);

        }).catch((err) => {
            setAlertInfo({message :err, isOpen:true});
        });
        
    },[]); //<--처음에만 호출하도록

    /**
     * 판매직원 등록될 때(SC, SA, PE)
     */ 
    useEffect(() => {
        // shouldValidate 옵션 -> 해당 값 변할시 valiation trigger 수행
        setValue("icntv_div_cnt", [watch('sc_emp_id'), watch('sa_emp_id'), watch('pe_emp_id')].filter((e) => e).length, { shouldValidate: isSubmitted });

    }, [watch('sc_emp_id'), watch('sa_emp_id'), watch('pe_emp_id')])

    /**
     * ew모델 변경될 때
     */
    useEffect(() => {
        requestEwPrice();
    }, [watch('ew_model_cd')]);

    /**
     * ew구분 변경될 때
     */
    useEffect(() => {
        requestEwPrice();
    }, [watch('ew_product_cd')]);

    /**
     * ew상품가격, 인센티브가격 조회
     */
    const requestEwPrice = () =>{
        let ewModelCd = getValues('ew_model_cd');
        let isCr = getValues('ew_cr_yn');
        let ewProductCd = getValues('ew_product_cd');
        
        setValue('discount_price', "0");
        setValue('sell_price', "0");
        
        if(!!ewModelCd && !!ewProductCd){
            let requestParam = {"ew_model_cd" : ewModelCd, "ew_product_cd" : ewProductCd, "dealer_cd" : userInfo.dealer_cd};
            crm4u.ewApiGetEwPrice(requestParam).then((res)=>{
                
                setValue('product_price', utils.setComma(res.SALES));
                setValue('total_icntv',res.ICNTV);
                
                if(!isCr){
                    setValue('sell_price', utils.setComma(res.SALES));
                }
                else{
                    setValue('discount_price', utils.setComma(res.SALES));
                }
            }).catch((err)=>{
                setValue('product_price', '0');
                setAlertInfo({message :err, isOpen:true});
            });
        }
    }
    /**
     * 할인가격 변경 될 때
     */    
    useEffect(() => {
        let discountPrice = Number(getValues('discount_price')?.replaceAll(",", "")??0); //할인가격
        let productPrice = Number(getValues('product_price')?.replaceAll(",", "")??0); //상품가격
        
        if(discountPrice > productPrice) {
            setValue('discount_price', productPrice.toLocaleString()); 
            return;
        }

        if(discountPrice !== ""){
            let sellPrice = productPrice - discountPrice;
            setValue('sell_price', sellPrice.toLocaleString());
        }
        else{
            setValue('sell_price', "");
        }
    }, [watch('discount_price')]);
    /**
     * 판매지점 변경될 때
     */
    const onChangeShowroom = (value) => {
        for(let i=0; i<showRoomList.length; i++){
            if(value === showRoomList[i].showroom_no){
                setValue("sell_showroom_phone_no", showRoomList[i].phone_no??"");
                setValue("showroom_nm",showRoomList[i].showroom_nm??"");
            }    
        }
    }
    /**
     * 구분 onChange
     */ 
    const onChangeEwDiv = (e, value) => {
        setValue("ew_product_cd", e.target.value, { shouldValidate: true });
        const defaultMessage = "등록된 데이터가 없습니다.";
        setAvailEnrollDt(defaultMessage);
        setAvailDistance(defaultMessage);
        setWrntdPeriod(defaultMessage);

        setEwDivCdNm(e.target.innerText);

        setProductGuide("");
        let selEwDiv = ewDivCd[e.target.id];
        //val4 NEW or HAVE
        setEwDiv(selEwDiv);

        //d = 일, m = 월, y = 년

        let availEnrollDt = selEwDiv.val2;
        const df = availEnrollDt?.[availEnrollDt?.length - 1];
        
        setAvailEnrollDtFlag(df);

        availEnrollDt = availEnrollDt?.replace(/\D/g, ''); //숫자 외 문자 제거

        if(availEnrollDt?.trim() && selEwDiv.val3?.trim()) {
            setAvailEnrollDt(`${availEnrollDt}`);
            setAvailDistance(`${utils.setComma(selEwDiv.val3)}`);
        }

        ewPeriodCd.map((item) => {
            if(item.val1 === selEwDiv.val1 && !!Number(item.val1)){
                setWrntdPeriod(`${item.val1}년 또는 ${utils.setComma((String(Number(selEwDiv.val5))))}km`);
            }
        });
    }

    useEffect(() => {
        //연장보증 개시일 변경
        calEwStartDt();
        calEwEndDt();
    }, [ewDiv]);

    /**
     * 차량정보 readonly 초기화
     */
    const resetCarReadOnly = () => {
        setCarReadOnly(false);
        setCarModelRO(false);
        setCarTypeRO(false);
        setCarYearRO(false);
        setCarNoRO(false);
        setIsoVinRO(false);
    }

    /**
     * 기본정보 modal창 display 
     **/
    const onBasicInfoModal = useCallback((props)=>{
        setBasicInfoModalYn(props);
    });

    /**
     * 기본정보 modal창에서 data선택시 셋팅 
     **/
    const inputBasicInfo = (selRow) =>{
        resetCarReadOnly();

        setValue("cont_no",selRow.cont_no);
        setValue("iso_vin",selRow.iso_vin, {shouldValidate: isSubmitted});
        setValue("car_no",selRow.car_no, {shouldValidate: isSubmitted});
        setValue("ew_client_nm",selRow.client_nm, {shouldValidate: isSubmitted});
        setValue("client_phone_no", selRow.phone_no, {shouldValidate: isSubmitted});
        setValue("client_no", selRow.client_no);
        setValue("client_car_no", selRow.client_car_no);

        //법인명 
        setClnCompList(() => {
            let clnList = [];

            if(selRow.client_comp_nm??"") clnList.push({client_comp_nm : selRow.client_comp_nm});
            if(selRow.client_comp_nm1??"") clnList.push({client_comp_nm : selRow.client_comp_nm1});
            if(selRow.client_comp_nm2??"") clnList.push({client_comp_nm : selRow.client_comp_nm2});
            
            return clnList;
        });
        setValue("ew_corp_nm", selRow.client_comp_nm); 

        setCarInfo(selRow.car_model_no, selRow.car_class_no, selRow.car_year_no);
        setIsContNo(true);
        setClnReadOnly(true);
        
        if(!!selRow.car_model_no && !!selRow.car_class_no  && !!selRow.car_year_no && !!selRow.car_no && !!selRow.iso_vin ){
            setIsNewCar(false);
            setCarReadOnly(true);
        }
        else if(!!selRow.car_model_no || !!selRow.car_class_no  || !!selRow.car_year_no || !!selRow.car_no || !!selRow.iso_vin){
            setIsNewCar(false);
            setCarReadOnly(false);
            if(!!selRow.car_model_no){
                setCarModelRO(true);
            }
            if(!!selRow.car_class_no){
                setCarTypeRO(true);
            }
            if(!!selRow.car_year_no){
                setCarYearRO(true);
            }
            if(!!selRow.car_no){
                setCarNoRO(true);
            }
            if(!!selRow.iso_vin){
                setIsoVinRO(true);
            }
        }
        else{
        setIsNewCar(true);
        setCarReadOnly(false);
        }
    };

    /** 
     * 기본정보 선택 시 모델/차종/년식 세팅 
     **/
    const setCarInfo = async (carModelNo, carClassNo, carYearNo) => {
        carModelNo = carModelNo??"";
        carClassNo = carClassNo??"";
        carYearNo = carYearNo??"";

        let carTypeList = "";
        //1. 모델 세팅
        setValue("car_model_no", carModelNo, {shouldValidate: isSubmitted});
        for(let i=0; i<carModel.length; i++){
            if(carModelNo === carModel[i].model_no){
                setValue("model_nm",carModel[i].model_nm??"");
            }    
        }
        if(!carModelNo) {
            onChangeCarModel(carModelNo);
            return;
        }

        await crm4u.ewApiGetCarType(carModelNo).then((res) =>{
            carTypeList = res;
            setCarType(res);
            if(res.filter((obj) => obj["class_no"] === carClassNo).length === 0) carClassNo = "";
        });
        if(!carClassNo) return;

        //2. 차종 세팅
        setValue("car_class_no", carClassNo, {shouldValidate: isSubmitted});
        await crm4u.ewApiGetCarYear(carModelNo,carClassNo).then((res) => {
            setCarYear(res);
            //3. 년식 세팅
            setValue("car_year_no", carYearNo, {shouldValidate: isSubmitted});

            const ewModelCd = carTypeList.find((obj) => obj["class_no"] === carClassNo)??"";
            setValue("ew_model_cd", ewModelCd["ew_model_cd"]??"");
        });

        
    }
    //차종 값 변경 됐을 때
    useEffect(()=>{
        let car_class_no = getValues("car_class_no");
        if(!!car_class_no){
            for(let i=0; i<carType.length; i++){
                if(car_class_no === carType[i].class_no){
                    setValue("class_nm",carType[i].class_nm??"");
                }    
            }
        }
    },[carType]);
    /**
     * 사원조회 modal창 display 
     **/
    const onEmpSearchModal = useCallback((props)=>{
        if(props.show) {
            setInitEmpSearchModal().then(res => {
                props = {...props, ...res};
                setEmpModalInfo(props, setEmpSearchModalYn(props.show));
            });
        }else {
            setEmpSearchModalYn(props.show);
        }
        
    });

    const onClnSignView = () => {
        
        crm4u.popClnSignView();
        
    }
    const getSignData = (e) => {
        let info = e.data.split("/");
        if(info !== "" && e.data !== "load"){
            setValue("cont_no", "");
            setValue("client_no", info[0]);
            setValue("ew_client_nm", info[1]);
            setValue("client_phone_no", info[2]);
            setValue("ew_corp_nm", info[3]);
            setValue("client_new", "Y");
            setClnReadOnly(true);
            registNewCar(true);
        }
         
    }
    useEffect(() => {
        window.addEventListener('message', getSignData);
        return () => {
            window.removeEventListener('message', getSignData);
        }
    });
    /**
     * 개인정보이용동의 체크 시 세팅
     */
    const inputClnSign = (data) => {
        setValue("client_new", "Y");
    }
    /**
     * 고객 modal data 세팅
     */
    const inputClnInfo = (selRow) => {
        resetCarReadOnly();

        setValue("cont_no",selRow.cont_no);
        setValue("iso_vin",selRow.iso_vin, {shouldValidate: isSubmitted});
        setValue("car_no",selRow.car_no, {shouldValidate: isSubmitted});
        setValue("client_no", selRow.client_no);
        setValue("client_car_no", selRow.client_car_no);
        setValue("ew_client_nm",selRow.client_nm, {shouldValidate: isSubmitted});
        setValue("client_phone_no", selRow.phone_no, {shouldValidate: isSubmitted});
        //법인명 
        setClnCompList(() => {
            let clnList = [];

            if(selRow.client_comp_nm??"") clnList.push({client_comp_nm : selRow.client_comp_nm});
            if(selRow.client_comp_nm1??"") clnList.push({client_comp_nm : selRow.client_comp_nm1});
            if(selRow.client_comp_nm2??"") clnList.push({client_comp_nm : selRow.client_comp_nm2});
            
            return clnList;
        });
       setValue("ew_corp_nm", selRow.client_comp_nm); 
       setCarInfo(selRow.car_model_no, selRow.car_class_no, selRow.car_year_no);
       setClnReadOnly(true);
       setIsNewCln(false);
       setIsContNo(false);
       if(!!selRow.car_model_no && !!selRow.car_class_no  && !!selRow.car_year_no && !!selRow.car_no && !!selRow.iso_vin ){
        setIsNewCar(false);
        setCarReadOnly(true);
       }
       else if(!!selRow.car_model_no || !!selRow.car_class_no  || !!selRow.car_year_no || !!selRow.car_no || !!selRow.iso_vin){
        setIsNewCar(false);
        setCarReadOnly(false);
        if(!!selRow.car_model_no){
            setCarModelRO(true);
        }
        if(!!selRow.car_class_no){
            setCarTypeRO(true);
        }
        if(!!selRow.car_year_no){
            setCarYearRO(true);
        }
        if(!!selRow.car_no){
            setCarNoRO(true);
        }
        if(!!selRow.iso_vin){
            setIsoVinRO(true);
        }
       }
       else{
        setIsNewCar(true);
        setCarReadOnly(false);
       }
    };
    /**
     * 고객조회 modal창 display 
     **/
    const onClnSearchModal = useCallback( async (props)=>{
        if(props.show) {
            const dept = await crm4u.getDeptList(userInfo.showroom_no);
            props = {
                ...props,
                selectAuth: selectAuth,
                showRoomList: showRoomList,
                deptList: dept.deptList,
            }
            setClnModalInfo(props, setClnSearchModalYn(props.show));
        }else {
            setClnSearchModalYn(props.show);
        }
    });

    /**
     * 차량 신규 등록
     */
    const registNewCar = (checked) => {
        // 차량정보 초기화
        if(checked){
            onChangeCarModel("");
            setValue("client_car_no", "");
            setValue("iso_vin", "", {shouldValidate: isSubmitted});
            setValue("car_no", "", {shouldValidate: isSubmitted});

            setIsNewCar(true);
            setCarReadOnly(false);
            setCarModelRO(false);
            setCarTypeRO(false);
            setCarYearRO(false);
            setIsoVinRO(false);
            setCarNoRO(false);
        }
    }
    /**
     * 품의서 번호 있을 경우 신규등록, 차량등록 제한
     */
    const delContNo = () => {
        initInput();
        setIsNewCln(false);
        setIsNewCar(false);
        setIsContNo(false);
        setValue("cont_no", "");
        setClnCompList([]);
    }

    /**
     * 고객 신규 등록
     */
    const registNewCln = (checked) => {
        if(checked){
            initInput();
            setIsNewCar(true);
            setIsNewCln(true);
            setCarReadOnly(false);
            setClnReadOnly(false);
            setCarModelRO(false);
            setCarTypeRO(false);
            setCarYearRO(false);
            setClnCompList([]);
        }
        else{
            setIsNewCar(false);
            setIsNewCln(false);
            setCarReadOnly(true);
            setClnReadOnly(true);
        }
    }

    const initInput = () => {
        onChangeCarModel("");
        setValue("client_car_no", "");
        setValue("client_no", "");
        setValue("iso_vin", "", {shouldValidate: isSubmitted});
        setValue("car_no", "", {shouldValidate: isSubmitted});
        setValue("ew_client_nm", "",{shouldValidate: isSubmitted});
        setValue("client_phone_no", "",{shouldValidate: isSubmitted});
        setValue("ew_model_cd", "");
        setValue("ew_corp_nm","");
        setValue("sc_emp_nm", "");
        setValue("sa_emp_nm", "");
        setValue("pe_emp_nm", "");
        setValue("sc_emp_id", "");
        setValue("sa_emp_id", "");
        setValue("pe_emp_id", "");
    }
    /**
     * 사원조회 modal 초기값 세팅 (전시장 -> 부서 -> 사원 )
     */
    const setInitEmpSearchModal = async() => {
        // 전시장
        let initData = {
            showRoomList: showRoomList,
            deptList: [],
            empList: [],
            totalCount: 0,
            size: 0
        };

        try{
            // 부서
            const dept = await crm4u.getDeptList(userInfo.showroom_no);
            initData = {...initData, deptList: dept.deptList};
    
            // 사용자
            const empBody = {
                showroom_no: userInfo.showroom_no, 
                dept_no: userInfo.dept_no, 
                emp_nm: "", 
                pageNum: 1
            }
            const emp = await crm4u.getEmpList(empBody);
            initData = {
                ...initData,
                empList: emp.empList,
                totalCount: emp.totalCount,
                size: emp.size
            }
        } catch(e) {
            setAlertInfo({message :e, isOpen:true});
        }
        
        return initData;
    }

    /**
     * 사원정보 modal창에서 data선택시 셋팅 
     **/
    const inputEmpInfo = (data) =>{
        setValue(data.idTarget, data.empId);
        setValue(data.nmTarget, data.empNm);
    };

    /**
     * 사원정보 한꺼번에 합치기
     **/
    useEffect(() =>{
        let sc_emp = getValues("sc_emp_nm") === "" ? "" : " " + getValues("sc_emp_nm");
        let sa_emp = getValues("sa_emp_nm") === "" ? "" : " " + getValues("sa_emp_nm");
        let pe_emp = getValues("pe_emp_nm") === "" ? "" : " " + getValues("pe_emp_nm");

        let sales_emp_nm = sc_emp + pe_emp + sa_emp;
        setValue("sales_emp_nm", sales_emp_nm);

    }, [watch("sc_emp_nm"), watch("sa_emp_nm"), watch("pe_emp_nm")]);

    /**
     * 차량모델 변경 
     **/
    const onChangeCarModel = (value) =>{
        setValue('car_model_no', value, {shouldValidate: isSubmitted});
        setCarType([]);
        setCarYear([]);
        setValue("ew_model_cd","", {shouldValidate: isSubmitted});
        setValue("car_class_no","", {shouldValidate: isSubmitted});
        setValue("car_year_no","", {shouldValidate: isSubmitted});
        setValue("product_price", "");
        setValue("discount_price", "");

        if(value !== ""){
            let modelNo = getValues('car_model_no');
            crm4u.ewApiGetCarType(modelNo).then((res) =>{
                setCarType(res);
            }).catch((err)=>{
                setAlertInfo({message :err, isOpen:true});
            });
            for(let i=0; i<carModel.length; i++){
                if(value === carModel[i].model_no){
                    setValue("model_nm",carModel[i].model_nm??"");
                }    
            }
        }
    }
    /**
     * 차종 변경 
     **/
    const onChangeCarType = (value) => {
        const ewModelCd = carType.find((obj) => obj["class_no"] === value)??""["ew_model_cd"]??"";
        setCarYear([]);
        setValue('car_class_no', value, {shouldValidate: isSubmitted});
        setValue("ew_model_cd", ewModelCd, {shouldValidate: isSubmitted});
        setValue("car_year_no","", {shouldValidate: isSubmitted});
        setValue("product_price", "");
        if(value !== ""){
            let car_model_no = getValues('car_model_no');
            let car_class_no = getValues('car_class_no');
            crm4u.ewApiGetCarYear(car_model_no,car_class_no).then((res)=>{
                setCarYear(res);
            }).catch((err) => {
                setAlertInfo({message :err, isOpen:true});
            });

            for(let i=0; i<carType.length; i++){
                if(value === carType[i].class_no){
                    setValue("ew_model_cd",carType[i].ew_model_cd??"");
                    setValue("class_nm", carType[i].class_nm??"");
                }    
            }
        }
    }
    /**
     * 휴대폰 번호 변경 시
     */
    useEffect(()=>{
        setValue("ew_client_phone", getValues("client_phone_no"));
    }, [watch("client_phone_no")]);

    /**
     * 차량등록일 값이 변경될 때
     **/
    useEffect (()=>{
        if(getValues('car_enroll_dt')){
            calEwStartDt();
            calEwEndDt();

            //상품판매일 - 차량등록일 계산
            if(getValues('ew_product_sales_dt')){
                const ewProductSalesDt = getValues('ew_product_sales_dt');
                const carEnrollDt = getValues('car_enroll_dt');
                let diffDate = ewProductSalesDt.diff(carEnrollDt, 'days');
                diffDate = diffDate < 0 ? 0 : diffDate??0;
                setElapsedDt(diffDate);       
            }
            
        }
 
    }, [watch('car_enroll_dt')]);

    /**
     * elapsedDt : 차량등록일로부터 상품판매일까지 몇일인지 계산한 값
     * availEnrollDt : ew상품별 가입 가능한 차량등록일 
     * availDistance : ew상품별 가입 가능한 주행거리
     * signup_mileage : 사용자가 입력한 가입시 주행거리
     **/
    useEffect (()=>{
        validationSignupCondition();

    }, [elapsedDt, availEnrollDt, availDistance, watch('signup_mileage')])

   /**
     * 상품판매일 값이 변경될 때
     **/
   useEffect (()=>{
    if(getValues('car_enroll_dt') && getValues('ew_product_sales_dt')){
        const ewProductSalesDt = getValues('ew_product_sales_dt');
        const carEnrollDt = getValues('car_enroll_dt');
        let diffDate = ewProductSalesDt.diff(carEnrollDt, 'days');
        diffDate = diffDate < 0 ? 0 : diffDate??0;
        setElapsedDt(diffDate);
    }

    }, [watch('ew_product_sales_dt')]);

    /**
     * 연장보증 개시일 계산 함수
     */
    const calEwStartDt = () => {
        const carEnrollDt = getValues('car_enroll_dt');
        if(!carEnrollDt) return;

        //차량등록일 가져오기 
        const dateEnrollDt = new Date(carEnrollDt);
        //차량등록일 + val6 -> 차량등록일 + 설정년도로 변경
        const ewBaseYear = ewDiv['val6']??3;
        const ewBaseDt = calcDaysAfter(ewBaseYear, 'y', dateEnrollDt);
        const dateEwStartDt = new Date(dateEnrollDt.setDate(dateEnrollDt.getDate()+ ewBaseDt));

        setValue('ew_start_dt', dayjs(dateEwStartDt), {shouldValidate: isSubmitted});
    }

    /**
     * 연장보증 종료일 계산 함수 
     **/
    const calEwEndDt = () => {
        //연장보증 종료일 자동계산 (=연장보증 개시일+담보기간)
        const startDt = getValues('ew_start_dt');

        if(startDt) {
            let dateEwStartDt = new Date(startDt);

            //연장보증 종료일 계산 변경 (3년 -> 1095일)
            //let dateEwEndDt = new Date(dateEwStartDt.setFullYear(dateEwStartDt.getFullYear()+Number(selEwPeriod.val1)));
            let dateEwEndDt = new Date(dateEwStartDt.setDate(dateEwStartDt.getDate()+ (Number(selEwPeriod.val1) * 365)));
            setValue('ew_end_dt', dayjs(dateEwEndDt), {shouldValidate: isSubmitted});
        }

    };    

    /**
     * 담보기간 클릭할때 
     **/
    const onChangeEwPeriod = (e) =>{
        setValue("warranty_period_cd", e.target.value, { shouldValidate: true });
        //선택값 셋팅
        setSelEwPeriod(ewPeriodCd[e.target.id]);      
    };

    /**
     * 담보기간 값 변경 될 때 
     **/
    useEffect (() => {
        //1) 연장보증 종료일 계산
        calEwEndDt();
        //2) 담보 주행거리 값 셋팅
        setValue("warranty_mileage", utils.setComma(selEwPeriod?selEwPeriod.val2:'0'));
         
        //3) 보험종료 주행거리 계산
        const numEwStartKm = Number(ewStartKm?.replaceAll(",",""));
        const numEwDriveKm = Number(getValues("warranty_mileage")?.replaceAll(",",""));
        setValue("insurance_end_mileage", utils.setComma((numEwStartKm+numEwDriveKm).toString()));
        
    },[selEwPeriod]);
    
    /**Form Submit 
     * 1) 전자계약서 토큰 생성  
     * 2) 전자계약서 생성 요청 (url생성)
     * 3) crm ew temp등록
     * 4) 전자계약서 url호출 및 서명
     * 5) crm ew 등록
    */
    const onSubmit = async (fieldValue)=> {
        if(!window.confirm("전자계약서를 작성하시겠습니까?")) return;
        
        setLoadingInfo({isOpen:true});
        
        try {
            //1. 가입 조건 확인
            if(!validationSignupCondition()) {
                alert("상품 가입 조건을 확인하세요.");
                return;
            }

            //2.차량 중복 검사
            let duplCar = await crm4u.getSameEwDtList(fieldValue);
            if(duplCar['result'].length > 0){
                alert(`${fieldValue.car_no} 차량의 보증 기간이 중복되었습니다. 
                \n계약서 번호 : ${duplCar.result[0]['ew_cont_no']??""}\n보증 개시일 : ${utils.toStringByFormat(duplCar.result[0].ew_start_dt??"")}\n보증 종료일 : ${utils.toStringByFormat(duplCar.result[0].ew_end_dt??"")}`);
                return ;
            }

            
            //3.전자계약서 문서 생성
            const templateData = {
                templateId: templateId,
                signerId: signerId,
                templateTitle: templateTitle,
                empNm: userInfo.emp_nm,
                empPhoneNo: userInfo.phone_no,
                ewDiv: ewDiv,
                ewData: fieldValue
            };
            const template = await signOk.createSignOkTemplate(templateData, signOk.makeEwSigner(templateData)); 

            fieldValue = {
                            ...fieldValue,
                            ew_product_sales_dt: fieldValue.ew_product_sales_dt.format("YYYYMMDD"),
                            ew_start_dt: fieldValue.ew_start_dt.format("YYYYMMDD"),
                            ew_end_dt: fieldValue.ew_end_dt.format("YYYYMMDD"),
                            car_enroll_dt: fieldValue.car_enroll_dt.format("YYYYMMDD"),
                            ew_cr_yn: fieldValue.ew_cr_yn ? "Y" : "N",
                            status: "N",
                            ew_signok_prog_status: "ESPS00010",
                            "document_id" : template.result.id,
                            "result_code" : template.code,
                            "result_message" : template.message,
                            "view_url" : template.result.signer[0].view_url,
                        }
                         
            //4. EW 등록
            delete fieldValue.ew_client_phone; //json에서 삭제(dto와 항목 맞추기위해)
            delete fieldValue.ew_tax_invoice_check;
            delete fieldValue.sell_showroom_phone_no;
            const ewContNo = await crm4u.insertEwMng(fieldValue);

            //5.전자계약서 열기
            navigate('/ewElecForm', {state: {url: template.result.signer[0].view_url
                                            ,ewData : {...fieldValue, 
                                                        ewContNo: ewContNo, 
                                                        ewDivCdNm: ewDivCdNm,
                                                        ewClientPhone: watch("ew_client_phone"),
                                                        sellShowroomPhoneNo: watch("sell_showroom_phone_no")
                                                    }
                                            ,templateData: templateData
                                            }

                                    });

        } catch (err) {
            setAlertInfo({message :err, isOpen:true});
        } finally {
            setLoadingInfo({isOpen:false});
        }
    };
    
    /**
     * Submit 후 Validation Error 콜백 함수
    */
    const onError = (errors, e) =>  {
       alert("입력 값을 확인하세요.");
    }

    const checkCR = (checked) => {
        let ewPrice = getValues('product_price');
        if(checked==="Y"){
            setValue('discount_price', utils.setComma(ewPrice));
        }
        else{
            setValue('discount_price', "0");
        }
    };

    const checkTax = (checked) => {
        if(checked === "Y"){
            setValue('tax_bill_req_cd', "Y");
        }
        else{
            setValue('tax_bill_req_cd', "N");
        }
    };


    /**
     * UTF8 변환된 length 확인 후 setValue
     */
    const setValueByUTF8MaxLength = (name, value, maxLength) => {

        //특수문자 제거 <, >, ', "
        setValue(name, value?.replace(/[<>"']/g, ""), {shouldValidate: isSubmitted });

        let utf8Value = new TextEncoder().encode(value);

        const lastByte = utf8Value[utf8Value.length - 1];
        const lastChar = new TextDecoder().decode(new Uint8Array([lastByte]));

        let removeByte = /^[a-zA-Z0-9]$/.test(lastChar) ? 1 : 3;
        
        if(utf8Value.length - maxLength >= 3) utf8Value = utf8Value.slice(0, maxLength + 1);

        if(utf8Value.length > maxLength) {
            const validBytes = utf8Value.slice(0, utf8Value.length-removeByte);
            const validValue = new TextDecoder().decode(validBytes); 

            setValue(name, validValue?.replace(/[<>"']/g, ""), {shouldValidate: isSubmitted });
        }
    }

    //날짜 공통 Validation 
    const validationDate = (date, range) => {

        //1. 날짜형식 확인
        if(isNaN(date)) return false;

        //2. 오늘날짜 기준 전후
        const today = dayjs(new Date());
        (Number(date.diff(today, "y", true)));
        if(Number(date.diff(today, "y", true)) > range || Number(date.diff(today, "y", true)) < -range) return false;

        return true;
    }

    //연장보증 개시일, 종료일 Validation 
    const validationStartAndEndDt = (beforeName, date) => {
        
        //1. 날짜형식 확인
        if(isNaN(date)) return false;

        //2. 선행 날짜 확인
        const beforeDt = getValues(beforeName);
        if(date.diff(beforeDt, "d") > 0) {
            return validationDate(date, 20);
        } else {
            return false;
        };
    }

    //가입조건 validation
    const validationSignupCondition = () => {

        const thisAvailEnrollDt = calcDaysAfter(availEnrollDt, availEnrollDtFlag);
        const thisAvailDistance = isNaN(availDistance?.replaceAll(",", "")) ? 9999999 : Number(availDistance?.replaceAll(",", ""));
        const thisSignupMileage = Number(watch("signup_mileage")?.replaceAll(",", ""));
        let validResult = true;

        setCheckAvailEnrollDt('');
        setCheckAvailDistance('');

        //1. 가입가능 차량등록일이 지난 경우
        if(elapsedDt > thisAvailEnrollDt) {
            setCheckAvailEnrollDt(`${availEnrollDt + dtFlag[availEnrollDtFlag]??'일'} 이내가 아닙니다.`);
            validResult = false;
        }
            

        //2. 가입시 주행거리가 초과된 경우
        if(thisSignupMileage > thisAvailDistance) {
            setCheckAvailDistance(availDistance+"Km 이내가 아닙니다.");
            validResult = false;
        }
        

        return validResult;
    }

    //오늘 기준 '개월', '년' 일로 변환
    const calcDaysAfter = (number, flag, date) => {
        const today = date ? new Date(date) : new Date();
        let futureDate = new Date(today);

        // 숫자 부분과 문자 부분을 분리
        const numberPart = parseInt(number);
    
        // 문자 부분에 따라 날짜 계산
        switch (flag) {
            case 'd':
                futureDate.setDate(today.getDate() + numberPart);
                break;
            case 'm':
                futureDate.setMonth(today.getMonth() + numberPart);
                break;
            case 'y':
                futureDate.setFullYear(today.getFullYear() + numberPart);
                break;
            default:
                futureDate.setDate(today.getDate() + numberPart);
                break;
        }
    
        // 오늘부터 미래 날짜까지의 일 수 계산
        const timeDifference = futureDate.getTime() - today.getTime();
        const daysBetween = Math.ceil(timeDifference / (1000 * 3600 * 24));
        
        return daysBetween;
    }

    //새로고침 방지
    const preventClose = (e) => {
        e.preventDefault();
        e.returnValue = ""; //Chrome에서 동작하도록; deprecated
    };
       
    useEffect(() => {
        (() => {
          window.addEventListener("beforeunload", preventClose);
        })();
       
        return () => {
          window.removeEventListener("beforeunload", preventClose);
        };
    }, []);
    return (
        <div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
        <input type="hidden" {...register("dealer_cd")}/>
        <input type="hidden" {...register("writer")}/>
        <input type="hidden" {...register("total_icntv")}/>
        <input type="hidden" value="0" {...register("icntv_div_cnt", {min: {value: 1, message: "한 명 이상의 판매 직원을 등록하세요."}})}/>
        <input type="hidden" {...register("model_nm")} />
        <input type="hidden" {...register("class_nm")} />
        <input type="hidden" {...register("sales_emp_nm")} />
        <input type="hidden" {...register("tax_bill_req_cd")} className={errors.tax_bill_req_cd? "validation-error" : "required"} />
        <input type="hidden" {...register("client_no")}/>
        <input type="hidden" {...register("client_car_no")}/>
        <input type="hidden" {...register("client_new")} />
        <br/>

              
        
        
        <ContentPaper elevation={3}>
            <div className='subTitle'>
                <TitleChip label="출고고객" icon={<CreateIcon/>}/>               
                <div className='subTitle-btn'>
                    <Button variant="outlined" size='small' onClick={()=>{onBasicInfoModal(true)}}  startIcon={<SearchIcon />}>품의서 조회 </Button>
                </div>
            </div>
            <Grid container spacing={{ xs: 2, md: 3 }} >
                <Grid  xs={xsVal} sm={smVal} md={mdVal}>
                        <TextField size='small'
                                label="품의서번호"
                                fullWidth
                                {...register("cont_no")}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{    readOnly: true,
                                                    endAdornment: (<InputAdornment position="end">
                                                                            {!!watch("cont_no") ? <IconButton sx={{pr: 0}}
                                                                                                            onClick={() => delContNo()}>
                                                                                                            <ClearIcon  />
                                                                                                </IconButton>
                                                                            : ""}
                                                                    </InputAdornment>)}}
                                                    onChange={(e) => {setValueByUTF8MaxLength("cont_no", e.target.value, 20)}}
                        />                 
                </Grid>
            </Grid>
            <div className='info-text'>
                <div><InfoCheckIcon/>KCC오토 신차 출고고객인 경우 검색이 가능합니다.</div>
            </div> 

        </ContentPaper>
        <ContentPaper elevation={3}>
            <div className='subTitle'>
                <TitleChip label="고객정보" icon={<CreateIcon/>}/>
                {/* {!isContNo ? <FormControlLabel  sx={{marginLeft: "3px"}} 
                                  label='신규고객'
                                  control={<Checkbox size="small" checked={isNewCln} onChange={(e) => {registNewCln(e.target.checked)}}/>} 
                /> : ""} */}
                <div className='subTitle-btn'>
                    <Button variant="outlined" size='small' onClick={()=>{onClnSearchModal({show: true})}}  startIcon={<SearchIcon />}>고객/차량 조회 </Button>&nbsp;
                    
                </div>
            </div> 
            <Grid container spacing={{ xs: 2, md: 3 }} >
                <Grid  xs={xsVal} sm={smVal} md={mdVal}> 
                    <TextField size='small'
                               fullWidth
                               label="고객명"
                               InputProps={{ readOnly: true}}
                               InputLabelProps={{ shrink: true }}
                               className= {errors?.ew_client_nm ? "validation-error" : "required"} 
                               {...register("ew_client_nm", {required: "고객명을 입력하세요."})}
                               onChange={(e) => {setValueByUTF8MaxLength("ew_client_nm", e.target.value, 100)}}
                    />
                   
                    {errors?.ew_client_nm && <WarningTextBox>{errors?.ew_client_nm?.message}</WarningTextBox>}                         
                </Grid>
                <Grid  xs={xsVal} sm={smVal} md={mdVal}> 
                    <TextField label="고객 휴대폰번호"
                               id="phoneNo"
                               defaultValue=""
                               fullWidth
                               size='small'
                               InputLabelProps={{shrink: true}}
                               InputProps={{readOnly: true}}
                               inputProps={{maxLength: 13}}
                               className={errors?.client_phone_no ? "validation-error" : "required"} 
                               {...register("client_phone_no", {required: "전화번호를 입력하세요.", 
                                                             pattern: {value: utils.regExp("phone"), message: "전화번호 형식을 확인하세요."},
                                                             onChange: (e) => {setValue("client_phone_no", e.target.value)}})}
                    />  
                    {errors?.client_phone_no && <WarningTextBox>{errors?.client_phone_no?.message}</WarningTextBox>}                         
                </Grid>
                <Grid  xs={xsVal} sm={smVal} md={mdVal}>  
                    {
                        clnCompList.length === 0 ? (
                            <TextField size='small'
                                    fullWidth
                                    label="법인명"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("ew_corp_nm")}
                                    onChange={(e) => {setValueByUTF8MaxLength("ew_corp_nm", e.target.value, 200)}}
                            /> 
                            ) : (
                            <CPSelectTag 
                               control={control}
                               data={clnCompList}
                               name="ew_corp_nm" 
                               value="client_comp_nm" 
                               text="client_comp_nm" 
                               defaultMessage="선택"
                               label="법인명"
                            />           
                        )
                    }
                </Grid>
                    <>
                    <Grid xs={12}>
                        <Button variant="outlined" size='small'  fullWidth onClick={()=>{onClnSignView()}}>
                            고객 신규 등록
                        </Button>
                    </Grid>
                </>
            </Grid>
           
        </ContentPaper>

        <ContentPaper elevation={3}>
            <div className='subTitle'>
                <TitleChip label="차량정보" icon={<CreateIcon/>}/>
                {!isContNo ? <FormControlLabel  sx={{marginLeft: "3px"}} 
                                  label='신규차량'
                                  control={<Checkbox size="small" checked={isNewCar} disabled={isNewCar} onChange={(e) => {registNewCar(e.target.checked)}}/>} 
                /> : ""}
            </div>
            <Grid container spacing={{ xs: 2, md: 3 }} >
                <Grid   xs={6} sm={4} md={4}>
                    <CPSelectTag 
                                            control={control}
                                            data={carModel}
                                            name="car_model_no" 
                                            rules={{required: "모델을 선택하세요."}}
                                            value="model_no" 
                                            text="model_nm" 
                                            label="모델"
                                            defaultMessage="선택"
                                            onChange={(e) => {onChangeCarModel(e.target.value)}}
                                            className={errors.car_model_no? "validation-error" : "required"}
                                            readOnly={carReadOnly||carModelRO}
                                        />         
                    {errors?.car_model_no && <WarningTextBox>{errors?.car_model_no?.message}</WarningTextBox>}
                </Grid>
                <Grid  xs={6} sm={4} md={4}>
                    <CPSelectTag 
                                            control={control}
                                            data={carType}
                                            name="car_class_no" 
                                            rules={{required: "차종을 선택하세요."}}
                                            value="class_no" 
                                            text="class_nm" 
                                            label="차종"
                                            defaultMessage="선택"
                                            className={errors.car_class_no? "validation-error" : "required"} 
                                            readOnly={carReadOnly||carTypeRO}
                                            onChange={(e) => {onChangeCarType(e.target.value)}}
                                        />
                    {errors?.car_class_no && <WarningTextBox>{errors?.car_class_no?.message}</WarningTextBox>}
                </Grid>
                <Grid  xs={6} sm={4} md={4}>
                    <CPSelectTag 
                                            control={control}
                                            data={carYear}
                                            name="car_year_no" 
                                            value="car_year_no" 
                                            text="car_year_nm" 
                                            label="년식"
                                            defaultMessage="선택"
                                            readOnly={carReadOnly||carYearRO}
                                        />
                </Grid>
                <Grid  xs={6} sm={4} md={4}>
                    <TextField size='small'
                               fullWidth
                               label="차대번호"
                               InputProps={{ readOnly: (carReadOnly || isoVinRO)}}
                               InputLabelProps={{ shrink: true }}
                               className= {errors?.iso_vin ? "validation-error" : "required"} 
                               {...register("iso_vin", {required: "차대번호를 입력하세요."})}
                               onChange={(e) => {setValueByUTF8MaxLength("iso_vin", e.target.value, 20)}}
                    />
                    {errors?.iso_vin && <WarningTextBox>{errors?.iso_vin?.message}</WarningTextBox>}
                </Grid>
                <Grid  xs={6} sm={4} md={4}>
                    <TextField size='small'
                               fullWidth
                               label="차량번호"
                               InputProps={{ readOnly: (carReadOnly || carNoRO)}}
                               InputLabelProps={{ shrink: true }}
                               className= {errors?.car_no ? "validation-error" : "required"} 
                               {...register("car_no", {required: "차량번호를 입력하세요."})}
                               onChange={(e) => {setValueByUTF8MaxLength("car_no", e.target.value, 20)}}
                    />
                    {errors?.car_no && <WarningTextBox>{errors?.car_no?.message}</WarningTextBox>}
                </Grid>
                <Grid  xs={6} sm={4} md={4}>
                    <CPSelectTag 
                                            control={control}
                                            data={ewModelCd}
                                            name="ew_model_cd" 
                                            rules={{required: "코드를 선택하세요."}}
                                            value="detail_cd_id" 
                                            text="cd_nm" 
                                            label="EW모델코드"
                                            defaultMessage="선택"
                                            className={errors.ew_model_cd? "validation-error" : "required"}
                                        />
                    {errors?.ew_model_cd && <WarningTextBox>{errors?.ew_model_cd?.message}</WarningTextBox>}
                </Grid>                                                
            </Grid>
        </ContentPaper>

        <ContentPaper elevation={3}>
            <div className='subTitle'>
                <TitleChip label="직원정보" icon={<CreateIcon/>}/>
            </div> 
            <Grid container spacing={{ xs: 2, md: 3 }} >
                <Grid  xs={xsVal} sm={smVal} md={mdVal}>  
                    <CPSelectTag 
                               control={control}
                               rules={{ required: '판매지점을 선택하세요.' }}
                               data={showRoomList}
                               name="sell_showroom_no" 
                               value="showroom_no" 
                               text="showroom_nm" 
                               label="판매지점"
                               onChange={(e) => {onChangeShowroom(e.target.value)}}
                               className={errors.sell_showroom_no ? "validation-error" : "required"} 
                    />        
                    {errors?.sell_showroom_no && <WarningTextBox>{errors?.sell_showroom_no?.message}</WarningTextBox>}                      
                </Grid>
                <Grid  xs={12} sm={8} md={8}>    
                    <Stack direction="row" spacing={1}>
                        <TextField size='small'
                                   fullWidth
                                   label="SC"
                                   InputLabelProps={{ shrink: true }}
                                   className= {errors?.icntv_div_cnt ? "validation-error" : "required"} 
                                   InputProps={{ readOnly: true,
                                                 endAdornment: (<InputAdornment position="end">
                                                                    {!!watch("sc_emp_id") ? <IconButton sx={{pr: 0}}
                                                                                                        onClick={() => {setValue("sc_emp_id", ""); setValue("sc_emp_nm", "")}}>
                                                                                                        <ClearIcon  />
                                                                                            </IconButton>
                                                                                           :<IconButton sx={{pr: 0}}
                                                                                                        onClick={()=>{onEmpSearchModal({show: true, title: "SC 조회", target: "sc"})}}>
                                                                                                        <SearchIcon />
                                                                                            </IconButton>}
                                                                </InputAdornment>)
                                                 
                                                 
                                                }}
                                   {...register("sc_emp_nm")}
                        />
                        <TextField sx={{ display: 'none' }} type="hidden" {...register("sc_emp_id")}/>
                        <TextField size='small'
                                   label="SA"
                                   fullWidth
                                   className= {errors?.icntv_div_cnt ? "validation-error" : "required"}
                                   InputLabelProps={{ shrink: true }}
                                   InputProps={{ readOnly: true,
                                                 endAdornment: (<InputAdornment position="end">
                                                                    {!!watch("sa_emp_id") ? <IconButton sx={{pr: 0}}
                                                                                                        onClick={() => {setValue("sa_emp_id", ""); setValue("sa_emp_nm", "")}}>
                                                                                                        <ClearIcon  />
                                                                                            </IconButton>
                                                                                           :<IconButton sx={{pr: 0}}
                                                                                                        onClick={()=>{onEmpSearchModal({show: true, title: "SA 조회", target: "sa"})}}>
                                                                                                        <SearchIcon />
                                                                                            </IconButton>}
                                                                </InputAdornment>)
                                                            }}
                                                {...register("sa_emp_nm")}
                        />
                        <TextField sx={{ display: 'none' }} type="hidden" {...register("sa_emp_id")}/>
                                         
                                       
                        <TextField  size='small'
                                    label="PE"
                                    fullWidth
                                    className= {errors?.icntv_div_cnt ? "validation-error" : "required"}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true,
                                                  endAdornment: (<InputAdornment position="end">
                                                                    {!!watch("pe_emp_id") ? <IconButton sx={{pr: 0}}
                                                                                                        onClick={() => {setValue("pe_emp_id", ""); setValue("pe_emp_nm", "")}}>
                                                                                                        <ClearIcon  />
                                                                                            </IconButton>
                                                                                           :<IconButton sx={{pr: 0}}
                                                                                                        onClick={()=>{onEmpSearchModal({show: true, title: "PE 조회", target: "pe"})}}>
                                                                                                        <SearchIcon />
                                                                                            </IconButton>}
                                                                </InputAdornment>)
                                                            }}
                                                {...register("pe_emp_nm")}
                        />
                        <TextField sx={{ display: 'none' }} type="hidden" {...register("pe_emp_id")}/>
                                       
                    </Stack>   
                    {errors?.icntv_div_cnt ? 
                    <WarningTextBox>{errors?.icntv_div_cnt?.message}</WarningTextBox> :
                    <HelperTextBox>한 명 이상의 판매 직원을 등록하세요.</HelperTextBox> }                         
                </Grid>
            </Grid>
        </ContentPaper>        
        
        <ContentPaper elevation={3}>
            <div className='subTitle'><TitleChip label="EW상품" icon={<CreateIcon/>}/></div>
            {(Object.keys(ewDivCd).length>0)?
            (<Controller
                control={control}
                name="ew_product_cd"
                rules={{ required: 'EW상품을 선택하세요.' }}
                render={({field}) => (
                    <>
                    {[...Array(Math.ceil(ewDivCd.length / 6))].map((_, groupIndex) => (
                        <ToggleButtonGroup
                            key={groupIndex}
                            {...field}
                            exclusive
                            value={watch("ew_product_cd")}
                            color="primary"
                            size="small"
                            fullWidth
                            className= {errors?.ew_product_cd? "validation-error" : "required"}
                            onChange={onChangeEwDiv}
                            >
                                {ewDivCd.slice(groupIndex * 6, (groupIndex + 1) * 6).map((radio, idx) => (
                                    <ToggleButton 
                                        key={idx}
                                        id={groupIndex * 6 + idx}
                                        value={radio.detail_cd_id} 
                                        sx={{
                                            wordBreak: 'keep-all',
                                            ...(groupIndex !== 0 && {borderTop: 'none'})
                                        }}
                                    >
                                        {radio.cd_nm}
                                    </ToggleButton>
                                ))}
                        </ToggleButtonGroup>
                    ))}
                    </>
                    )
                    }
                />) : (<TextField disabled value="조회된 데이터가 없습니다." size='small' fullWidth sx={{"& .Mui-disabled": { WebkitTextFillColor: '#ff5050'} }}   />)}
            
            <br/>
            {errors?.ew_product_cd && <WarningTextBox>{errors?.ew_product_cd?.message}</WarningTextBox>} 
            <div className='info-text'>
                <div><InfoCheckIcon/> 차량등록일 : <span>{availEnrollDt?availEnrollDt + (!isNaN(availEnrollDt) ? `${dtFlag[availEnrollDtFlag]??'일'} 이내` : "" ):<Skeleton animation={false} width='100px' />}</span></div>
                <div><InfoCheckIcon/> 가입가능 주행거리 : <span>{availDistance?availDistance + (!isNaN(availDistance?.replaceAll(",", "")) ? 'km' : "" ):<Skeleton animation={false} width='100px' />}</span></div>
                <div><InfoCheckIcon/> 보장기간 및 주행거리 : <span>{wrntdPeriod?wrntdPeriod:<Skeleton animation={false} width='100px' />}</span> </div>
            </div>                     
            <small>{productGuide}</small>
        </ContentPaper>
        <Grid container spacing={{ md: 3 }}>
            <Grid xs={12} md={8}>
                                        
                    <ContentPaper elevation={3}>
                        <div className='subTitle'><TitleChip label="상품정보" icon={<CreateIcon/>}/></div>
                        <Grid container spacing={{ xs: 2, md: 3 }} >
                            <Grid  xs={6}>
                                <CPDatePicker name="ew_product_sales_dt" 
                                              rules={{ required: "상품판매일을 입력하세요.", validate : {validationDate : value => {return validationDate(value, 5)}} }}
                                              control={control} 
                                              label="EW상품판매일"
                                              className={errors.ew_product_sales_dt ? "validation-error" : "required"} 
                                />      
                                {errors?.ew_product_sales_dt && <WarningTextBox>{errors?.ew_product_sales_dt?.message}</WarningTextBox>}                              
                                {(errors?.ew_product_sales_dt && errors.ew_product_sales_dt.type === "validationDate") && <WarningTextBox>상품판매일을 확인하세요.</WarningTextBox>}
                            </Grid>
                            <Grid  xs={6}>
                                <TextField size='small'
                                           fullWidth
                                           label="가입시 주행거리"
                                           InputLabelProps={{ shrink: true }}
                                           InputProps={{ maxLength: 7,
                                                          endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                                       }}
                                           className={errors.signup_mileage? "validation-error" : "required"} 
                                           {...register("signup_mileage",{onChange:(e)=> setValueByUTF8MaxLength("signup_mileage", utils.setComma(e.target.value), 13), required: "가입시 주행거리를 입력하세요."})}
                                />
                                {errors?.signup_mileage && <WarningTextBox>{errors?.signup_mileage?.message}</WarningTextBox>}
                                <WarningTextBox>{checkAvailDistance}</WarningTextBox>
                            </Grid>
                            <Grid  xs={6}>
                                <CPDatePicker label="차량등록일" 
                                              name="car_enroll_dt" 
                                              control={control}
                                              className={errors.car_enroll_dt? "validation-error" : "required"} 
                                              rules={{required: "차량등록일을 입력하세요.", validate : {validationDate : value => {return validationDate(value, 20)}}}}
                                />
                                <HelperTextBox>차량등록일</HelperTextBox>
                            </Grid>
                            <Grid  xs={6}>
                                <TextField size='small'
                                           fullWidth
                                           label="보험개시 주행거리"
                                           helperText = ""
                                           InputProps={{ endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                                         readOnly: true
                                                       }}
                                           value= {utils.setComma(ewStartKm)}
                                           {...register("insurance_start_mileage",{required: true})}
                                />
                                <HelperTextBox>(MBK지원)</HelperTextBox> 
                            </Grid>
                            <Grid  xs={6}>
                                <div className='itemTitle'> <InfoCheckIcon/>차량등록일로부터 상품판매일까지 </div>
                                <TextField size='small'
                                           fullWidth
                                           sx={{marginTop:'4px !important'}}
                                           InputProps={{ readOnly: true }}
                                           value={elapsedDt + "일 경과"}         
                                />
                                <WarningTextBox>{checkAvailEnrollDt}</WarningTextBox>
                            </Grid>
                            <Grid  xs={6}>
                                <div className='itemTitle'>담보기간</div>
                                <div className='mt4'>
                                    {(Object.keys(ewPeriodCd).length>0)?
                                        (<Controller control={control}
                                                     name="warranty_period_cd"
                                                     rules={{ required: '상품정보를 선택하세요.' }}
                                                     render={({field }) => ( <ToggleButtonGroup {...field}
                                                                                                exclusive
                                                                                                fullWidth
                                                                                                color="primary"
                                                                                                size="small"
                                                                                                value={watch("warranty_period_cd")}
                                                                                                className= {errors?.warranty_period_cd? "validation-error" : "required"} 
                                                                                                onChange={onChangeEwPeriod} 
                                                                             > {ewPeriodCd.map((radio, idx) => ( <ToggleButton key={`ewPeriod-${idx}`}
                                                                                                                               id={idx}
                                                                                                                               value={radio.detail_cd_id} >{radio.cd_nm}
                                                                                                                 </ToggleButton>
                                                                                ))}
                                                                             </ToggleButtonGroup>
                                                    )}
                                        />) : (<TextField disabled value="조회된 데이터가 없습니다." size='small' fullWidth sx={{"& .Mui-disabled": { WebkitTextFillColor: '#ff5050'} }} />)}
                                  
                                    {errors?.warranty_period_cd && <WarningTextBox>{errors?.warranty_period_cd?.message}</WarningTextBox>}        
                                    </div>
                            </Grid>
                            <Grid  xs={6}>
                                <CPDatePicker label="연장보증 개시일"  
                                              name="ew_start_dt" 
                                              control={control}
                                              className={errors.ew_start_dt? "validation-error" : "required"}
                                              rules={{required: "연장보증 개시일을 입력하세요.", validate : {validationDate : value => {return validationStartAndEndDt("car_enroll_dt",value)}}}}
                                              onChange={() => {calEwEndDt()}}
                                />
                                <HelperTextBox>(차량등록일+{ewDiv['val6']??3}년)</HelperTextBox> 
                            </Grid>
                            <Grid  xs={6}>
                                <TextField size='small'
                                           fullWidth
                                           label="담보 주행거리"
                                           helperText = ""
                                           InputProps={{ readOnly: true, 
                                                         endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                                       }}
                                           {...register("warranty_mileage",{onChange:(e)=>setValue("warranty_mileage", utils.setComma(e.target.value))})}   
                                />
                                <HelperTextBox>(담보기간에 따라)</HelperTextBox>
                            </Grid>
                            <Grid  xs={6}>
                                <CPDatePicker label="연장보증 종료일" 
                                              name="ew_end_dt" 
                                              control={control}
                                              className={errors.ew_end_dt? "validation-error" : "required"} 
                                              rules={{required: "연장보증 종료일을 입력하세요.", validate : {validationDate : value => {return validationStartAndEndDt("ew_start_dt", value)}}}}
                                />
                                <HelperTextBox>(개시일+담보기간)</HelperTextBox>
                            </Grid>
                            <Grid  xs={6}>
                                <TextField size='small'
                                           fullWidth
                                           label="보험종료 주행거리"
                                           helperText = ""
                                           readOnly={true}
                                           InputProps={{ readOnly: true, 
                                                         endAdornment: <InputAdornment position="end">km</InputAdornment>,
                                                       }}
                                           {...register("insurance_end_mileage")}
                                />
                                <HelperTextBox>(보험개시주행거리+담보주행거리)</HelperTextBox>
                            </Grid>

                        
                        </Grid>
                    </ContentPaper>
                                
            </Grid>
            <Grid xs={12} md={4}>
                    <ContentPaper1 elevation={3}>
                            <div className='subTitle'><TitleChip label="상품가격" icon={<CreateIcon/>}/></div>
                            <Grid container spacing={2} sx={{marginBottom:'4px'}}>
                                <Grid xs={6} sm={4} md={12}>
                              
                                <FormLabel className='itemTitle'>CR</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="ew_cr"
                                    name="ew_cr"
                                    {...register("ew_cr_yn")}
                                >
                                <FormControlLabel value="Y" control={<Radio size="small" onChange={(e) => checkCR(e.target.value)} />} label='적용' />
                                <FormControlLabel value="N" control={<Radio size="small" onChange={(e) => checkCR(e.target.value)}/>} label='미적용' />
                                </RadioGroup>
                                                                
                                </Grid>
                                <Grid xs={6} sm={8} md={12}>

                                <Controller
                                    control={control}
                                    name="tax_bill_req_cd"
                                    rules={{ required: "세금계산서 여부를 확인하세요."}}
                                    render={({field}) => (
                                        <>
                                        <FormLabel className={'itemTitle '+ (errors.tax_bill_req_cd? 'validation-error' : 'required')}>세금계산서</FormLabel>
                                        <RadioGroup
                                            {...field}
                                            row
                                            aria-labelledby="ew_tax_invoice"
                                        >
                                        <FormControlLabel value="TBR00020" control={<Radio size="small" onChange={(e) => checkTax(e.target.value)} />} label='발행요청' />
                                        <FormControlLabel value="TBR00010" control={<Radio size="small" onChange={(e) => checkTax(e.target.value)} />} label='미발행' />
                                        </RadioGroup></>
                                    )}
                                />
                                {errors?.tax_bill_req_cd && <WarningTextBox>{errors?.tax_bill_req_cd?.message}</WarningTextBox>}
                                
                                </Grid>
                            </Grid>
       
                  
                        <Stack direction={{xs:'column', sm:'row', md:'column'}} spacing={2} >
                            <TextField
                                        size='small'
                                        fullWidth
                                        label="상품가격"
                                        helperText=""
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ 
                                            readOnly: true, 
                                            endAdornment: <InputAdornment position="end">원</InputAdornment>
                                        }}
                                        defaultValue='0'
                                        {...register('product_price')}
                            />
                            <TextField
                                        size='small'
                                        fullWidth
                                        label="할인가격"
                                        helperText=""
                                        InputLabelProps={{ shrink: true}}
                                        defaultValue='0'
                                        InputProps={{ 
                                            endAdornment: <InputAdornment position="end">원</InputAdornment>
                                        }}
                                        {...register('discount_price', {onChange:(e)=> setValue("discount_price", utils.setComma(e.target.value))})}
                            />
                            <TextField
                                        size='small'
                                        fullWidth
                                        label="판매가격"
                                        InputLabelProps={{ shrink: true}}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end">원</InputAdornment>
                                        }}
                                        defaultValue='0'
                                        {...register('sell_price')}
                            />
                        </Stack>                          
                    </ContentPaper1>
               
            </Grid>    
        </Grid>
            
        <Paper elevation={0} sx={{backgroundColor:"#e5f6fd", padding:"15px", marginBottom:"15px"}}>
            <Stack direction={{xs:'column', sm:'row', md:'row'}} spacing={2}>
                    <TextField
                            label="고객 휴대폰번호"
                            defaultValue=""
                            variant="standard"
                            size='small'
                            InputLabelProps={{shrink: true}}
                            inputProps={{ maxLength: 13}} 
                            className={errors.ew_client_phone? "validation-error" : "required"} 
                            {...register("ew_client_phone", {required: "전화번호를 입력하세요.", 
                                                             pattern: {value: utils.regExp("phone"), message: "전화번호 형식을 확인하세요."},
                                                             onChange: (e) => {setValue("ew_client_phone", e.target.value)}})}                               
                    />  
                    <div className="info-box" sx={{padding:"5px"}}>※ 해당번호의 카카오톡으로 완료된 전자계약서가 전달됩니다. </div>
            </Stack>
            {errors?.ew_client_phone && <WarningTextBox>{errors?.ew_client_phone?.message}</WarningTextBox>}        
        </Paper>  

        <div>
            <Button fullWidth variant="contained" size="large" type="submit"> 전자계약서 작성  </Button>
        </div>

        </form>

            {basicInfoModalYn && <CPAppSearchModal onBasicInfoModal={onBasicInfoModal} inputBasicInfo={inputBasicInfo}/>}
            {empSearchModalYn && <CPEmpSearchModal {...empModalInfo} onEmpSearchModal={onEmpSearchModal} inputEmpInfo={inputEmpInfo}/>}
            {clnSearchModalYn && <CPClnSearchModal {...clnModalInfo} onClnSearchModal={onClnSearchModal} inputClnInfo={inputClnInfo} />}
            {isNonFace && <CPManualBtn url={'https://dev.kcc.co.kr/wiki/pages/viewpage.action?pageId=45293304'}/>}
        </div>
    )
}

export default EwForm;