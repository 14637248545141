import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

// xs, 초소형: 0px
// sm, 소형: 600px
// MD, 매체: 900px
// LG, 대형: 1200px
// xl, 특대: 1536px
const theme = createTheme({
    typography: {
        fontFamily: 'Pretendard GOV'
    },
    components : {
        MuiCssBaseline: {
        styleOverrides: {

            html:{
            fontSize:'16px',
            '@media (min-width:0px)': {
                fontSize:'14px',
            },
            '@media (min-width:600px)': {
                fontSize:'17px',
            },
            '@media (min-width:900px)': {
                fontSize:'17px',
            },
            }
        }
        },

        MuiButton : {
        variants : [
            {
            props: {size:'large'},
            style: {fontSize: '1rem'}
            }

        ]
        },

        MuiInputBase : {
        styleOverrides: {
            
            root: ({theme,ownerState}) => ({
            backgroundColor : ownerState.readOnly ? '#ebebeb4a': null,
            //fontSize:'0.9rem'
            //...(ownerState.variant === 'readOnly' && {backgroundColor : '#ebebeb7a'}),
            }),
    
            input: {
            fontSize:'0.85rem',
            overflow:'hidden',
            textOverflow:'ellipsis'
            },

        }
        },

        MuiTypography : {
            styleOverrides: {
                root: {
                    fontSize:'0.85rem'
                }
            }
        },
  },

});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <RecoilRoot>
    <ThemeProvider theme={theme}> 
        <CssBaseline>
            <Container maxWidth="lg" sx={{padding: "0 !important"}}>
                <App />
            </Container>
        </CssBaseline>
        </ThemeProvider>
    </RecoilRoot>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
