import React, { useEffect, useState, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import EventIcon from '@mui/icons-material/Event';

    function CPDatePicker(props) {
        const [open, setOpen] = useState(false);

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            {props.control && (
                <Controller
                name={props.name}
                control={props.control}
                rules={props.rules}
                render={({ field }) => (
                        <DatePicker {...field}
                                    label={props.label}
                                    open={open}
                                    onOpen={() => setOpen(true)}
                                    onClose={() => setOpen(false)}
                                    onChange={(e) => {field.onChange(e); props.onChange && props.onChange(e)}}
                                    format="YYYY-MM-DD"
                                    className={props.className}
                                    slotProps={{ textField: { InputLabelProps: { shrink: true }, 
                                                            helperText: props.helperText, 
                                                            required:props.required, 
                                                            size:'small',
                                                            error: false,
                                                            InputProps: { 
                                                                endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <EventIcon/>
                                                                </InputAdornment>
                                                                )},
                                                            onClick: () => setOpen(true)
                                                            },
                                            }}
                                    sx={{
                                    width: '100%'
                                    }}
                        />
                )}
                />
            )}
            {!props.control && (
                <DatePicker 
                    label={props.label}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    onChange={(e) => {props.onChange && props.onChange(e)}}
                    format="YYYY-MM-DD"
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    sx={{
                        ...props.sx,
                        '& .MuiInputBase-input': {
                            cursor: 'pointer'
                        },
                        '& .Mui-readOnly': {
                            backgroundColor: 'white'
                        }
                    }}
                    className={props.className}
                    slotProps={{ textField: {
                                                InputLabelProps: { shrink: true }, 
                                                size:'small',
                                                error: false,
                                                fullWidth: true,
                                                readOnly: true,
                                                onClick: () => setOpen(true)
                                            },
                                actionBar: {
                                                actions: ['clear', 'accept'],
                                           }
                            }}
                />
            )
            }
            </LocalizationProvider>
        );
}

export default CPDatePicker;