import React, { useEffect, useState } from 'react';
/**외부 */
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
//Material
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


/**내부 */
import '../styles/Login.css';
import * as utils from '../commons/utils';
import { userState } from '../stores/UserState';
import * as crm4u from '../services/apis/crm4u/crmApi';
import * as signOk from '../services/apis/signOk/signOkApi';
import { alertState } from '../stores/ComponentState';
import CPCircularProgress from '../components/comm/CPCircularProgress';
import CPAppDownloadBtn from '../components/CPAppDownloadBtn';

function Login() {

    let navigate = useNavigate(); //페이지이동
    let params = useParams(); // url얻기
    const domain = params.domain;
    
    const [searchParams, setSearchParams] = useSearchParams();
    const setAlertInfo = useSetRecoilState(alertState); //alert셋팅

    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const [sessionOutMsg, setSessionOutMsg] = useState('');
    const [userInfo, setUserInfo] = useRecoilState(userState);

    const { register,
            handleSubmit,
            } = useForm({
                defaultValues: {
                emp_id: utils.getCookie("emp_id")
                }
            });

            
    useEffect(() => {
        if(searchParams.get('type') === 'simple') setSessionOutMsg("지정된 시간이 초과되어 자동 로그아웃되었습니다.\n다시 로그인하세요.");
    }, []);

    
    const onSubmit = async (data) => {
        setLoading(true);
        try {
            //1. 로그인 
            const login = await crm4u.login(data); 
            sessionStorage.setItem('domain', login.data.empInfo.domain);
            setUserInfo(login.data.empInfo);
            
            //2. signok 토큰 발급
            const signOkInfo = await crm4u.getSignokDealerInfo(login.data.empInfo.dealer_cd);
            signOkInfo[0] && await signOk.getSignOkToken(signOkInfo[0]['var1'], signOkInfo[0]['var2']);

            //SESSION OUT 팝업 로그인
            if(sessionOutMsg !== "") {
                window.close();
                return;
            };

            //3. REMEMBER 버튼 체크 -> 쿠키에 아이디 저장
            if(document.querySelector("#remember").checked === true) {
                utils.setCookie("emp_id", data.emp_id, 7);
            }else{
                utils.removeCookie("emp_id");
            }

            //4. 홈 화면으로 이동
            navigate('/home');
        } catch(err) {
            setAlertInfo({message :err, isOpen:true});
            //setMsg(err); 
        } finally {
            setLoading(false);
        }
    };

    /*
        컴포넌트 첫등장해서 로딩 끝난후에
        컴포넌트 재랜더링 되고 난 후
    */
    useEffect(() => {
        if(!utils.checkDealer(domain)) {
        alert("정확한 주소를 입력해주세요.");
        navigate("/");
        }
    }, []);

    // Login Event
    

    // EnterKey Event
    const enterKeyEvent = e => {
        if(e.key === 'Enter') 
        //checkLogin();
        handleSubmit(onSubmit);
    }

    return (
        <>
            <Container component="main" maxWidth="xs">
                <Box sx={{ 
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'center',
                           alignItems: 'center',
                           height: '100dvh',
                           paddingBottom: '10dvh'
                        }}
                >
                    <Box>
                        <div className="crm4u-logo">eform4u</div>
                        {/* <Typography variant="h3" className="crm4u-logo" display="inline">CRM4U</Typography> */}
                    </Box>
                    {sessionOutMsg && 
                        (
                            <Typography variant="body2" display="inline" color="text.secondary" sx={{ml: 1, whiteSpace: "pre-line", textAlign: "center"}}>
                                {sessionOutMsg}
                            </Typography>
                        )
                    }
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1}}>
                        <TextField margin="normal"
                                   required
                                   fullWidth
                                   label="Enter ID"
                                   name="email"
                                   //autoComplete="email"
                                   autoFocus
                                   {...register("emp_id",{required:true, maxLength:50})}
                        />
                   
                        <TextField margin="normal"
                                   required
                                   fullWidth
                                   name="password"
                                   label="Enter Password"
                                   type="password"
                                   //autoComplete="current-password"
                                   {...register("emp_pw",{required:true, maxLength:50})}
                                   onKeyDown={enterKeyEvent}
                        />
                        {/* <Typography variant="body2" display="inline" color="text.secondary" sx={{ml: 1}}>
                            {errors.emp_pw?.type === "required" && " Password를 입력해주세요."}
                        </Typography> */}
                        <TextField 	sx={{ display: 'none' }} type="hidden" {...register("comp_nm",{value:domain})} />
                        <FormControlLabel control={<Checkbox id="remember" color="primary" defaultChecked={!!utils.getCookie("emp_id")?.trim()} />}
                                          label="Remember me"
                                          sx={{display: 'block'}}
                        />
                        <Button type="submit"
                                fullWidth
                                variant="contained"
                                size="large"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={loading}
                        >   Login
                            {loading && <CPCircularProgress/>}
                        </Button>
                        {msg!==""?<Alert variant="outlined" severity="error" sx={{fontSize: "0.8rem"}}>{msg}</Alert>:null}
                    </Box>
                </Box>
            </Container>
            <CPAppDownloadBtn />
        </>
    );
}

export default Login;