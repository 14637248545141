import { atom } from 'recoil';

export const alertState = atom({
    key:"alertInfo",
    default: {
        isOpen  : false
       ,message : ""
    },
});

export const loadingState = atom({
    key:"loadingInfo",
    default: {
        isOpen  : false
    },
});

export const promptState = atom({
    key:"promptInfo",
    default: {
        prompt : null
    },
});