import { useState } from 'react';

/**내부 */
import * as utils from '../../commons/utils';

/**MUI */
import { Fab, Typography } from '@mui/material';

const CPManualBtn = ({url}) => {
  
    const [show, setShow] = useState(false);

    const handleClick = () => {
        const isMobile = utils.isMobile();

        isMobile && !show ? setShow(true) : window.open(url, '_blank');
    }

    return (
        <Fab
            onClick={handleClick}
            onMouseOver={() => !utils.isMobile() && setShow(true)}
            onMouseLeave={() => setShow(false)}
            variant='extended'
            sx={{
                position: 'fixed',
                backgroundColor: 'white',
                bottom: 70,
                right: 0,
                borderRadius: 0,
                borderTopLeftRadius: 25,
                borderBottomLeftRadius: 25,
            }}
        >
            {/* <ManualIcon /> */}
            <img style={{width: '30px'}} src="/images/manual/manual-icon.png" alt='메뉴얼'/>
            <Typography
                sx={{
                    transition: 'width 0.3s ease',
                    width: show ? '90px' : 0,
                    opacity: show ? 1 : 0,
                    marginLeft: show ? '8px' : '0',
                    whiteSpace: 'nowrap'
                }}
            >
                메뉴얼 보러가기
            </Typography>
        </Fab>
    )
};
  
export default CPManualBtn;
