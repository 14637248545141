import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import { userState } from '../stores/UserState';


export const PrivateRoute = () => {
    const userInfo = useRecoilValue(userState); 

    return userInfo.dealer_cd ? <Outlet /> : <Navigate to="/" />;
};