import { useState, useEffect } from 'react';

/**외부 */
import { useRecoilState, useSetRecoilState } from 'recoil';

/**내부 */
import * as utils from '../commons/utils.js';
import CPCarousel from './comm/CPCarousel.js';
import { promptState } from '../stores/ComponentState.js'

/**MUI */
import { Fab, Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseIcon from '@mui/icons-material/Close';

function CPAppDownloadBtn() {

    const [open, setOpen] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useRecoilState(promptState);
    const [showBtn, setShowBtn] = useState(false);

    const install = () => {
        if(utils.isIOS()) {
            setOpen(true);
            return;
        }
        if (!deferredPrompt?.prompt) {
            alert("이미 앱이 설치되어 있습니다.");
            return;
        }
        deferredPrompt.prompt()
        deferredPrompt.userChoice.then(() => setDeferredPrompt(null))
    }

    const clearPrompt = () => {
        setDeferredPrompt(null)
    }

    useEffect(() => {

        setShowBtn( ((!utils.isIOS() && deferredPrompt?.prompt) || utils.isIOS()) && !window.matchMedia('(display-mode: standalone)').matches);

        const handler = (e) => {
            e.preventDefault();
            setDeferredPrompt(e)
        }   

        window.addEventListener('beforeinstallprompt', handler)
        return () => {
            window.removeEventListener('beforeinstallprompt', handler)
        }
    }, [])


    const carouselItems = [
        {
          imgPath: '/images/install-guide/ios-guide1.jpg',
          imageStyle: { border: '1px solid #bcbcbc', borderRadius: '5px'},
          label : <Typography fontWeight={'bold'}>1. 주소표시줄 우측 <img style={{verticalAlign: 'sub'}} alt='공유 버튼' src='/images/install-guide/ios-share.svg'/> 버튼 클릭</Typography>
        },
        {
          imgPath: '/images/install-guide/ios-guide2.jpg',
          imageStyle: { border: '1px solid #bcbcbc', borderRadius: '5px'},
          label : <Typography fontWeight={'bold'}>2. 팝업 하단 '홈 화면에 추가' 클릭</Typography>
        },
        {
          imgPath: '/images/install-guide/ios-guide3.jpg',
          imageStyle: { border: '1px solid #bcbcbc', borderRadius: '5px'},
          label : <Typography fontWeight={'bold'}>3. 저장할 이름을 설정 후 '추가' 클릭</Typography>
        }
    ];

    return (
        <>
            {showBtn && (
            <>
                <Fab
                    onClick={install} 
                    variant='extended' 
                    sx={{
                        position: 'fixed', 
                        backgroundColor: 'white',
                        bottom: 50, 
                        right: 30
                    }}>
                    앱 다운로드
                    <FileDownloadOutlinedIcon />
                </Fab>

                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    fullWidth
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    앱 다운로드 메뉴얼
                    </DialogTitle>
                    <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                    </IconButton>
                    <DialogContent dividers sx={{textAlign: 'center'}}>
                        <CPCarousel items={carouselItems} sx={{width: '100%'}} isTitle={true}/>                
                    </DialogContent>
                </Dialog>
            </>
            )}
        </>
    )
}

export default CPAppDownloadBtn;