import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

/**
 * SELECT 태그 생성
 */
function CPSelectTag({ 
                    control,           //React Hook Form 제어 컴포넌트 설정
                    rules = "",        //React Hook Form validtaion 설정 
                    name,              //React Hook Form register 설정
                    data = [{}],       //Option 태그 생성 할 데이터
                    value = "",        //Option 태그 value 속성으로 data 배열 내부 각 object의 key 값
                    text = "",         //Option 태그 text 속성으로 data 배열 내부 각 object의 key 값
                    defaultMessage,    //Option 태그 제일 위 DefaultMessage / ex) 선택, 전체
                    className = "",    //클래스명
                    label = "",        //Label에 출력할 문구
                    readOnly = false , //ReadOnly 속성
                    onChange           //onChange 함수
                }) {
    
    const isEmptyObject = () => {
        const length = Object.keys(data[0]??{}).length;

        return length === 0;
    }
    
    return (
            <>
            {control && (
                <Controller
                    control={control}
                    name={name}
                    rules={rules}
                    render={({ field }) => (
                        <FormControl sx={{ display: 'flex', width:'100%'}} size="small" className={className} > 
                            {label && <InputLabel shrink>{label}</InputLabel>}
                            <Select {...field} 
                                    onChange={(e) => {field.onChange(e); onChange && onChange(e)}} 
                                    readOnly={readOnly}
                                    displayEmpty 
                                    input={label&&<OutlinedInput notched label={label}/>}>
                                {defaultMessage && <MenuItem value="">{defaultMessage}</MenuItem>}
                                {!isEmptyObject() && data.map( (obj, idx) => (
                                    <MenuItem key={idx} value={obj[value]??""}>{obj[text]??""}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                />
            )}
            {!control && (
                <FormControl sx={{ display: 'flex', width:'100%'}} size="small" className={className} > 
                    {label && <InputLabel shrink>{label}</InputLabel>}
                    <Select 
                        name={name}
                        onChange={(e) => {onChange && onChange(e)}} 
                        readOnly={readOnly}
                        displayEmpty 
                        defaultValue=""
                        input={label&&<OutlinedInput notched label={label}/>}>
                        {defaultMessage && <MenuItem value="">{defaultMessage}</MenuItem>}
                        {!isEmptyObject() && data.map( (obj, idx) => (
                            <MenuItem key={idx} value={obj[value]??""}>{obj[text]??""}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            </>
            )
    };
  
  export default CPSelectTag;