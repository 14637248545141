import { useState, Fragment } from 'react';
import dayjs from 'dayjs';
import { Box, Paper, Grid, TextField, Button } from '@mui/material';
import CPDatePicker from '../../components/comm/CPDatePicker';
import CPSelectTag from './CPSelectTag';

function CPSearchCondition( {conditions, onSearch} ) {

    const [searchParam, setSearchParam] = useState({});

    const handleSerchParam = (name, value) => {
        
        if(!name) return;

        setSearchParam((prevState)=> {
            return { ...prevState, [name]: value}
        });
    };

    const makeConditionComponent = (props) => {
        switch (props.type) {
            case "text":
                return (
                    <TextField 
                        name={props.name} 
                        fullWidth size="small" 
                        label={props.label} 
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => handleSerchParam(e.target.name, e.target.value)} 
                        onKeyDown={(e) => e.key === "Enter" && onSearch(searchParam)}
                    />
                )
            case "select":
                return (
                    <CPSelectTag 
                        data={props.data}
                        name={props.name}
                        value={props.value}
                        text={props.text}
                        defaultMessage={props.defaultMessage}
                        label={props.label}
                        onChange={(e) => handleSerchParam(e.target.name, e.target.value)}
                    />
                )
            case "date":
                return <CPDatePicker label={props.label} sx={{backgroundColor: "inherit"}} onChange={(e) => handleSerchParam(props.name, e && e.format("YYYY-MM-DD"))}/>
            case "date-range":
                return (
                    <Box sx={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                        {props?.items?.map((obj, index) => (
                            <Fragment key={`${obj.name}_${index}`}>
                                <CPDatePicker 
                                    name={obj.name} 
                                    label={obj.label} 
                                    sx={{width: "50%"}}
                                    minDate={obj.end && dayjs(searchParam[obj.link]??null)}
                                    maxDate={obj.start && dayjs(searchParam[obj.link]??null)}
                                    onChange={(e) => {handleSerchParam(obj.name, e && e.format("YYYY-MM-DD"));}}/>
                                {props.items.length !== index + 1 && <span style={{margin: "10px"}}>~</span>}
                            </Fragment>
                        ))}
                    </Box>
                 ); 
            default:
                break;
        }
    }

    return (
        <Paper sx={{marginTop: "5px"}}>
            <Box textAlign='right' pr={1}>
                <Button size='small' variant="contained" sx={{margin: "10px 10px 0 0"}} onClick={() => onSearch(searchParam)}>조회</Button>
            </Box>
            <Grid container p={1} sx={{margin: "8px 0"}}>
                {conditions.map((item, index) => (
                    <Grid key={index} item lg={item?.breakPoints?.lg??4} md={item?.breakPoints?.md??4} sm={item?.breakPoints?.sm??6} xs={item?.breakPoints?.xs??12} p={1} >
                        {makeConditionComponent(item)}
                    </Grid>    
                ))}
            </Grid>
        </Paper>
    )
}

export default CPSearchCondition