import React, { useEffect } from 'react';
import '../styles/Login.css';
import { Link } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { userState } from '../stores/UserState';
import CPAppDownloadBtn from '../components/CPAppDownloadBtn';

function DealerList() {
  const logout = useResetRecoilState(userState);

  useEffect(() => {
    logout();
  }, [])

  return (
      <div className="login-page">        
        <div className="login-form">   
          <div className="container">
            <section>
              <div className="crm4u-logo">eform4u</div>&nbsp;
              <div>전자계약서</div>  
            </section>
            <table style={{margin: "0 auto"}}>
              <thead>
                <tr>
                  <th>딜러명</th>
                  <th>URL</th>
                </tr>  
              </thead>
              <tbody>
                <tr>
                  <th>KCCAUTO</th>
                  <td><Link to="/kccauto">/kccauto</Link></td>
                </tr>
              </tbody>
            </table>            
          </div>
        </div>
        <CPAppDownloadBtn />
      </div>
    
  );
}

export default DealerList;