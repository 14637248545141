import { atom } from 'recoil';

export const userState = atom({
    key:"userInfo",
    default: {
        admin_yn: ""
        ,ars_email: ""
        ,ars_phone_no1: ""
        ,ars_phone_no2: ""
        ,ars_phone_no3: ""
        ,cln_homepage: ""
        ,comp_nm: ""
        ,dealer_cd: ""
        ,dealer_nm: ""
        ,dept_name: ""
        ,dept_no: ""
        ,duty: ""
        ,email: ""
        ,emp_id: ""
        ,emp_nm: ""
        ,inperpage: ""
        ,main_program: ""
        ,model_yn: ""
        ,pass_expire_dt: ""
        ,pass_expire_yn: ""
        ,pass_modify_cycle: ""
        ,phone_no: ""
        ,position: ""
        ,request_receipt_state: ""
        ,sale_vehicle_comp_nm: ""
        ,sales_yn: ""
        ,salesoffice_no: ""
        ,showroom_nm: ""
        ,showroom_no: ""
        ,vat_yn: ""
        ,domain: ""
        ,authList: []
    },
});
