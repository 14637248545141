import CircularProgress from '@mui/material/CircularProgress';


function CPCircularProgress() {
    return (
        <>
        <CircularProgress size={24}
                          sx={{ color: '#7d7d7d',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                              }}
                            />
        </>
    );
}

export default CPCircularProgress;