import * as utils from '../../commons/utils';

import { DataGrid } from '@mui/x-data-grid';
import { Box, Paper, Typography, Button } from '@mui/material';

function CPSearchResult( {columns, rows, rowId, totalCount, buttons} ) {
    return (
        <>
        <Paper sx={{marginTop: '10px'}}>
            <Box p={2} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography pl={1} fontWeight={'bold'} sx={{display: 'flex', flexDirection: 'column' ,justifyContent: 'center'}}>
                    총 {utils.setComma(totalCount)} 건
                </Typography>
                <Box>{buttons}</Box>
            </Box>
            <DataGrid
                disableColumnSorting
                disableColumnMenu
                autoHeight
                rows={rows}
                getRowId={(row) => row[rowId]}
                columns={columns.map((column) => ({ ...column, sortable: false }))}
                sx={{ 
                textAlign: 'center',
                '--DataGrid-overlayHeight': '100px',
                '.MuiDataGrid-columnHeaderTitleContainer' : {
                    justifyContent: 'center',
                },
                '.MuiDataGrid-cell' : {
                    justifyContent: 'center'
                },
                '.MuiDataGrid-columnHeaderTitle' : {
                    fontWeight: 'bold'  
                },
                '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                    },
                    '&.MuiDataGrid-root': {
                    border: 'none',
                    },
                '.MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus-within': {
                    outline: 'none'
                    },
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer'
                }  
                }}
                slots={{
                    noRowsOverlay: () => (
                        <Box sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>조회 결과가 없습니다.</Box>
                    )
                }}
                hideFooter
            />        
        </Paper>
        </>
    )
}

export default CPSearchResult