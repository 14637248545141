/**mui */
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
/**내부 */
import { alertState } from '../../stores/ComponentState'
/**외부 */
import { useRecoilState } from 'recoil';

function CPAlert() {

    const [alertInfo, setAlertInfo] = useRecoilState(alertState);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertInfo((prev)=>({...prev, isOpen:false}))
      };

    return (
        <>
            <Snackbar open={alertInfo.isOpen}
                      onClose={handleClose}
                      autoHideDuration={5000}
                      anchorOrigin={{ vertical:'bottom', horizontal:"center" }} >
                <Alert severity="error" variant="filled" elevation={6}  onClose={handleClose} >{alertInfo.message}</Alert>

            </Snackbar>
        </>

    );
}
export default CPAlert;