import * as confStore from '../stores/confStore';

/** number로 변환 후 comma */
export function setComma(val) { 
    const removeCommaValue = Number(String(val)?.replaceAll(",",""));
    if(isNaN(removeCommaValue)) return;
    return removeCommaValue.toLocaleString();
}

export function toStringByFormat(val) {

    if(val instanceof Date){
        const year = val.getFullYear();
        const month = leftPad(val.getMonth() + 1);
        const day = leftPad(val.getDate());
    
        return [year, month, day].join('-');
    } else {
        if(val?.length === 8){
            const year = val.substring(0,4);
            const month = val.substring(4,6); 
            const day = val.substring(6,8);
    
        return [year, month, day].join('-');
        }
    }

    return val;
}

function leftPad(value) {
    if (value >= 10) {
        return value;
    }
    return `0${value}`;
}

/** 정규식 */
export function regExp(key) {

    const regExp = {
        phone:  /^\d{2,3}-?\d{3,4}-?\d{4}$/
    }

    return regExp[key];
}

/** 숫자 외 문자 제거 + 하이픈 추가*/
export function onlyPhoneNumber(val) {
    return val ? val.replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(-{1,2})$/g, "") : '';

}

/** 숫자 외 문자 제거  */
export function onlyNumber(val) {
    return val ? val.replace(/[^0-9]/g, "") : '';
}

/** LoginPage 확인 */
export function isLoginPage(val) {
    const dealerArr = confStore.dealerList;
    let url = val.pathname;

    if(url === "/")  return false;

    for(var i=0; i<dealerArr.length; i++) {
        if(url === ("/"+dealerArr[i]) ){
            return false;
        }
    }
    
    return true;
}

/** dealer 유효성 체크 */
export function checkDealer(val) {
    const dealerArr = confStore.dealerList;

    for(var i=0; i<dealerArr.length; i++) {
        if(val === dealerArr[i] ){
            return true;
        }
    }
    
    return false;
}

/** Cookie에 값 저장 */
export function setCookie(cookieName, value, expireDay){
    const exdate = new Date();
    expireDay = expireDay??0;
    exdate.setTime(exdate.getTime() +  (expireDay * 24 * 60 * 60 * 1000));
    
    document.cookie = `${cookieName}=${encodeURIComponent(value)}; ${expireDay !== 0 ? "expires=" + exdate.toUTCString() : ""}`;
}

/** Cookie 값 꺼내오기 */
export function getCookie(cookieName) {
    const cookieValue = document.cookie.match('(^|;) ?' + cookieName + '=([^;]*)(;|$)');
    return cookieValue ? cookieValue[2] : null;
}

/** Cookie 삭제 */
export function removeCookie(cookieName) {
    setCookie(cookieName, '', -1);
}

/** 팝업 호출 
 * @example
    openPopup({
        url: "www.naver.com",
        target: "_blank",
        width: '300px',
        height: '300px',
        align: 'center',
        success: () => {alert('good~')},
        fail: () => {alert('실패')
    });
})*/
export function openPopup(options) {

    const url = options.url??'';
    const target = options.target??'_blank';
    const feature = options.width && options.height ? popupAlign(options.width, options.height, options.align) : '';
    const success = options.success;
    const fail = options.fail;

    const popup = window.open(url, target, feature);

    if((!popup || popup.closed || typeof popup.closed === 'undefined') && fail) fail();
    if(popup && popup.focus && !popup.closed && success) success();

    return popup;
}

/** 팝업 위치 정렬 */
export function popupAlign(width, height, align) {
    // width  : 팝업창 가로 크기
    // height : 팝업창 세로 크기
    const popupWidth = width;
    const popupHeight = height;
    
    // 브라우저 중앙 위치 계산
    // window.screenX : 브라우저의 현재 스크린 좌측상단 X좌표
    // window.screenY : 브라우저의 현재 스크린 좌측상단 Y좌표
   
    // window.outerWidth  : 브라우저의 전체 가로 크기
    // window.outerHeight : 브라우저의 전체 가로 크기
    let featureWindow = '';
    if(align === 'center') {
        const popupX = Math.round(window.screenX + (window.outerWidth/2) - (popupWidth/2));
        const popupY = Math.round(window.screenY + (window.outerHeight/2) - (popupHeight/2));
        
        // 윈도우 팝업창의 스타일 지정
        featureWindow = "width=" + popupWidth + ", height="+popupHeight
                          + ", left=" + popupX + ", top=" + popupY;
    } else {
        featureWindow = "width=" + popupWidth + ", height="+popupHeight;
    }
    return featureWindow;
}

/** 모바일 여부 (Mac OS도 포함) */
export function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mac/i.test(navigator.userAgent);
}

/** IOS 여부 (Mac OS도 포함) */
export function isIOS() {
    return /iPhone|iPad|iPod|Mac/i.test(navigator.userAgent);
}

/** 사용자 권한 체크
 * userAuthList : 로그인한 사용자권한(useInfo.auth_list)
 * checkAuthList : 확인할 권한 ex) ['AUT00010', 'AUT00430'] -> 둘 중에 하나라도 권한이 있는 경우 true 리턴
 */
export function isAuthorized(userAuthList, checkAuthList) {

    const userAuthCodes = userAuthList?.map(auth => auth.auth_cd);
    return checkAuthList.some(auth => userAuthCodes?.includes(auth));
}