import React from 'react';
import './styles/App.css';
import {BrowserRouter} from 'react-router-dom'
import RouterList from './RouterList.js';
import CPAlert from './components/comm/CPAlert';
import CPLoading from './components/comm/CPLoading';

function App() {
  return (
    <div>
      <BrowserRouter>
        <RouterList />  
      </BrowserRouter>
      <CPAlert/>
      <CPLoading/>
    </div>
  );
}

export default App;
