import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom'
import { Button, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
/**내부 */
import '../../styles/layouts/Header.css';
import { userState } from '../../stores/UserState';

function Header() {
	const userInfo = useRecoilValue(userState);
	const navigate = useNavigate();
	
	const logout = () => {
		axios({
			method:"get",
			url:"/apiLogout.do"
		}).then(()=>{
			navigate(`/${userInfo.domain}`);   
		}).catch(()=>{
			navigate(`/${userInfo.domain}`);
		});
	}

	return (
		<>
		<div className='header-area'>
			<div className='header-logo' onClick={() => navigate('/home')}>eForm4u</div>
			<div className='header-account'>
				<div style={{textAlign: 'center'}}>
					<Typography sx={{display: 'inline'}}>{userInfo.emp_nm}님</Typography>
					<Button color="inherit" onClick={logout}><LogoutIcon/>Logout</Button>
				</div>
			</div>
		</div> 
		
		</>
	);
}

export default Header;