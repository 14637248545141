import { Outlet } from 'react-router-dom';
import Header from '../components/layouts/Header.js'
import BottomNav from '../components/layouts/BottomNav.js';

/**RouterList.js에서 Route를 Layout이 감싸고 있고 모든 페이지에 적용됨  */
const Layout = () => {

    return (
        <div style={{
                backgroundColor:"#f9f9f9", 
                minHeight: "100dvh",
                display: "flex",
                flexDirection: "column"
            }}
        >
            <Header />
            <main style={{
                    padding:"20px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1
                }}
            >
                <Outlet />
            </main>
            <BottomNav />
        </div>
    );
};

export default Layout;