import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

function useCurrentViewport() {
    const theme = useTheme();

    const viewport = {
        xs: useMediaQuery(theme.breakpoints.only('xs')),
        sm: useMediaQuery(theme.breakpoints.only('sm')),
        md: useMediaQuery(theme.breakpoints.only('md')),
        lg: useMediaQuery(theme.breakpoints.only('lg')),
        xl: useMediaQuery(theme.breakpoints.only('xl'))
    };
    return viewport;
}

export default useCurrentViewport;