import React, { useState, useEffect, useRef } from 'react';
/**외부 */
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'; 
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
/**내부 */
import * as crm4u from '../services/apis/crm4u/crmApi';
import CPSelectTag from './comm/CPSelectTag';
import CPPaging from './comm/CPPaging';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { userState } from '../stores/UserState';
import { alertState } from '../stores/ComponentState';
import CPCircularProgress from './comm/CPCircularProgress';

function CPEmpSearchModal(props) {
   
    const userInfo = useRecoilValue(userState); //사용자정보
    const setAlertInfo = useSetRecoilState(alertState); //alert셋팅

	const { register,
            setValue,
            getValues,
            control,
	} = useForm({ defaultValues: {
                                    showroom_no: userInfo.showroom_no,
                                    dept_no: userInfo.dept_no
                }});

    // 검색조건 
	const [searchParam, setSearchParam] = useState({showroom_no: userInfo.showroom_no, dept_no: userInfo.dept_no, emp_nm: "", pageNum: 1});
    const init = useRef(true);

    // Select 태그 전시장 리스트
	const [showRoomList, setShowRoomList] = useState([]);
    // Select 태그 부서 리스트
	const [deptList, setDeptList] = useState([]);

    // 사용자 조회 결과
	const [result, setResult] = useState([]);
    // 결과 카운트
	const [totalCount, setTotalCount] = useState(0);
    // 한 페이지에 보여줄 행 수
	const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
    // 현재 페이지
    const [page, setPage] = useState(1);
    const [view, setView] = useState(false);

	const [loading, setLoading] = useState(false);
    
	const columns = [{ field: 'showroom_nm' ,headerName: '전시장명', sortable:false, width: 150}
					,{ field: 'dept_name'   ,headerName: '부서명', sortable:false, width: 150}
					,{ field: 'emp_id'      ,headerName: '아이디', sortable:false, width: 100}
					,{ field: 'emp_nm'      ,headerName: '이름', sortable:false, width: 100}
					,{ field: 'duty'        ,headerName: '직급', sortable:false, width: 100}];

    //init
    useEffect (() => {
        setShowRoomList(props.showRoomList);
        setDeptList(props.deptList);
        setTotalCount(props.totalCount);
        setItemsCountPerPage(props.size);
        setResult(props.empList, setView(true)); 
    },[]);
    
    //전시장 변경시 부서 세팅
    const changeShowRoom = (e) => {
        const showroomNo = e.target.value;
        
        setValue("dept_no", "");
        
        crm4u.getDeptList(showroomNo).then((res) => {
            setDeptList(res.deptList);
        });
    }
    //조회 버튼 클릭 -> 검색조건 세팅
    const clickSerchBtn = () => {
        init.current = false;
        setSearchParam(getValues());
    };

    //검색조건 세팅 후 조회
    useEffect(() => {
        if(init.current === false) getEmpList(1);
    }, [searchParam]);

    //사용자 조회
    const getEmpList = (pageNum) => {
        setLoading(true);
        
        setPage(pageNum);
        
        crm4u.getEmpList({...searchParam, pageNum: pageNum})
        .then((res)=>{
            setResult(res.empList);
            setTotalCount(res.totalCount);
            setItemsCountPerPage(res.size);
        })
        .catch((err)=>{
            setAlertInfo({message :err, isOpen:true});
        })
        .finally(()=>{
            setLoading(false);
        });
    };

    //결과 선택
    const tableRowEvents = (e) => {

            //부모창에 전달할 데이터
            const data = {
                idTarget: props.target + "_emp_id",
                nmTarget: props.target + "_emp_nm",
                empId: e.row.emp_id,
                empNm: e.row.emp_nm
            };

            props.inputEmpInfo(data); 

            //모달 종료
            props.onEmpSearchModal(false);
        
    }; 

    return (
        view &&
    <>
        <Dialog open={true}
                onClose={() => props.onEmpSearchModal(false)}
                maxWidth="md"
                >

            <DialogTitle sx={{ m: 0, p: 2, fontSize:'1.1rem', fontWeight:550}}>{props.title}</DialogTitle>
            <IconButton aria-label="close"
                        onClick={() => props.onEmpSearchModal(false)}
                        sx={{ position: 'absolute',
                            right: 8,
                            top: 8,
                            }}
            > <CloseIcon />
            </IconButton>

            <DialogContent dividers>
                <div className='subTitle'>
                    <div className='subTitle-btn'><Button size='small' variant="contained" onClick={() => clickSerchBtn()} disabled={loading}>조회{loading && <CPCircularProgress/>}</Button></div>
                </div>             
                
                <Stack direction="row" spacing={2} sx={{mb: 2, width:'100%'}} justifyContent="space-around">
          
                    <CPSelectTag 
                        control={control}
                        data={showRoomList}
                        name="showroom_no" 
                        value="showroom_no" 
                        text="showroom_nm" 
                        label="전시장"
                        onChange={(e) => changeShowRoom(e)}
                        defaultMessage="전체"
                        // {...register("showroom_no", {onChange: (e) => changeShowRoom(e)})}
                    />
              
                
                    {/* <Typography display="inline">부서</Typography> */}
                    <CPSelectTag 
                        control={control}
                        data={deptList}
                        name="dept_no" 
                        value="dept_no" 
                        text="dept_name"
                        label="부서" 
                        defaultMessage="전체"
                    />
                
             
                    {/* <Typography display="inline">사원명</Typography> */}
                    <TextField label="사원명" size='small' fullWidth {...register("emp_nm")} onKeyDown={e => e.key === "Enter" ? clickSerchBtn() : ""} />
                
                </Stack>
                <DataGrid
                    disableColumnMenu
                    autoHeight
                    rows={ result }
                    getRowId={(row) => row.emp_id}
                    columns={ columns }
                    onRowClick={(e) => tableRowEvents(e)}
                    sx={{'--DataGrid-overlayHeight': '100px',
                    '.MuiDataGrid-cell:focus': {
                        outline: 'none'
                        },
                        // pointer cursor on ALL rows
                    '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer'
                    }}}
                    slots={{
                        noRowsOverlay: () => (
                            <Box sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>조회 결과가 없습니다.</Box>
                        )
                    }}
                    hideFooter
                />
                <CPPaging page={page} totalItemsCount={totalCount} func={getEmpList} itemsCountPerPage={itemsCountPerPage}/>
            </DialogContent>
        </Dialog>
    </>
    );
}

    export default CPEmpSearchModal;