import React from 'react'; 

import { Routes, Route } from 'react-router-dom'

import Login from './pages/Login.js'
import Layout from './pages/Layout.js'
import DealerList from './pages/DealerList'
import Home from './pages/Home.js'
import EwForm from './pages/ewMng/EwForm.js'
import EwElecForm from './pages/ewMng/EwElecForm.js'
import SelectContract from './pages/contract/SelectContract.js';
import EwList from './pages/ewMng/EwList.js';
import { PrivateRoute } from './routes/PrivateRoute.js';


function RouterList() {

  return (
    <div> 
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<Layout />}>
            <Route exact path="/home" element={<Home />}/>
            <Route exact path="/contracts/select" element={<SelectContract />}/>
            <Route exact path="/ewForm" element={<EwForm />}/>
            <Route exact path="/ewList" element={<EwList />}/>
          </Route>
          <Route exact path="/ewElecForm" element={<EwElecForm />}/>
        </Route>

        <Route exact path="/" element={<DealerList />}/>
        <Route exact path="/:domain" element={<Login />}/>
        <Route path="*" element={<h3><b>NOT FOUND PAGE</b></h3>} />  {/* NOT FOUND PAGE 컴포넌트 생성 예정 */} 
      </Routes>
    </div>
  );
}

export default RouterList;
