/**mui */
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
/**내부 */
import { loadingState } from '../../stores/ComponentState'
/**외부 */
import { useRecoilState } from 'recoil';

function CPLoading() {

  const [loadingInfo, setLoadingInfo] = useRecoilState(loadingState);


    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingInfo.isOpen}
      > <CircularProgress color="inherit" />
     </Backdrop>
      );
}

export default CPLoading;