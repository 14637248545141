import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

/**내부 */
import * as confStore from '../stores/confStore';
import { userState } from '../stores/UserState';
import CPAppDownloadBtn from '../components/CPAppDownloadBtn';
import '../styles/Home.css';

/**mui */
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Grid, Box } from "@mui/material";

function Home() {
    const navigate = useNavigate();

    const userInfo = useRecoilValue(userState); 

	const menuList = confStore.menuList[userInfo.dealer_cd];
    const setActiveMenu = useSetRecoilState(confStore.activeMenu);

	const handleMenuClick = (activeMenu, page) => {
		if(!page) {
			alert("메뉴 준비중"); 
			return;
		}
        
        setActiveMenu(activeMenu);
        navigate(page);
	};

    return (
        <>
            <Box 
                sx={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    flexDirection: 'column', 
                    flex: 1,
                    padding: '0 20px 100px'
                    }}
            >
            <div className='page-title'>전체서비스</div>

            <Grid container spacing={4} >
                {menuList.map((menu) => (
                     <Grid key={`Menu_${menu.id}`} item lg={menuList.length === 1 ? 12 : 6} md={menuList.length === 1 ? 12 : 6} sm={12} xs={12}>
                        <Paper
                            elevation={2} 
                            sx={{ 
                                    borderRadius: 3,
                                    paddingBottom: 2,
                                    height: "100%"
                                }}
                        >
                            <List>
                                <div className='menu-title'>{menu.title}</div>
                                {menu?.child?.map((subMenu) => (
                                    <ListItemButton key={`SubMenu_${subMenu.id}`} >
                                        <ListItem onClick={() => handleMenuClick(menu.child, subMenu.route)}>
                                            <ListItemIcon>{subMenu.icon}</ListItemIcon>
                                            <ListItemText sx={{
                                                '.MuiListItemText-primary' : {
                                                    fontSize: '1rem'
                                                }
                                            }}
                                            >{subMenu.title}</ListItemText>
                                            <ArrowForwardIosIcon fontSize='small'/>
                                        </ListItem>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                ))}
                </Grid>
            </Box>
            <CPAppDownloadBtn />
        </>
    )
}

export default Home;